import React, { useState, useEffect, useRef  } from 'react';
import './userStoryComponent.css';
import '../../common/css/loader.css';
import '../../common/css/output.css';
import '../../common/css/formattedOutput.css';
import { createChatCompletionGPT4VisionSingleStory,createChatCompletionGPT4, createChatCompletionGPT4Vision, copyToClipboard, extractJSONFromText, renderJSON  } from '../../common/utils/utilityFunctions';

function VisionUserStoryComponent() {
  const [userRole, setUserRole] = useState('');
  const [description, setDescription] = useState('');
  const [output, setOutput] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loaderText, setLoaderText] = useState('');
  const [isSingleStory, setIsSingleStory] = useState(true);
  const [editableOutput, setEditableOutput] = useState('');
  const generateButtonRef = useRef(null); // Creating a ref for the button


  useEffect(() => {
    // If the button is enabled and the ref is attached to a DOM element, focus the button
    if (userRole && description && selectedImage && generateButtonRef.current) {
        generateButtonRef.current.focus();
        // console.log("generateButtonRef.current:\n",generateButtonRef.current);
    }
  

    let timer;
    if (isLoading) {
      timer = setInterval(() => {
        setCount(prevCount => prevCount + 1);
      }, 1000);
    } else {
      setCount(0);  // Reset the counter when loading is complete
    }
    return () => clearInterval(timer);  // Cleanup: stop the timer
  }, [isLoading, userRole, description, selectedImage]);

  const generatePrompt = (userRole, description) => {
    return `Generate a comprehensive User Stories per component identified for uploaded image. Context information is: Actor: ${userRole}, Feature: ${description}. Think critically. Ensure to comment on each of the Acceptance Criteria. Specifically interested in tracking features, edge cases, technical specifications for engineers. Be as detailed as possible as Your insights will be valuable in refining the implementation plan. Use the following JSON format for response: {
      "UserStories": [
        {
          "Title": "UserStory 1",
          "Description": "As a PLACEHOLDER, I want to be able to PLACEHOLDER, so that I can PLACEHOLDER",
          "Precondition": "",
          "AcceptanceCriteria": [
            {
              "CriterionTitle": "TITLE PLACEHOLDER",
              "Details": [
                "Detail 1 PLACEHOLDER",
                "Detail 2 PLACEHOLDER"
              ]
            }
            // ... additional criteria as needed
          ],
          "EdgeCases": [
            {
              "CaseTitle": "TITLE PLACEHOLDER",
              "Details": [
                "Detail 1 PLACEHOLDER",
                "Detail 2 PLACEHOLDER"
              ]
            }
             
          ]
        },
         
      ]
    }
    `;
  };

  function createPromptsFromComponents(imagedData) {
    
    // Remove the markdown code block syntax to isolate the JSON
    // imagedData = imagedData.replace(/^```json\n|\n```$/g, '');
    // console.log("imagedData:\n",imagedData);

    // If imagedData is a string, remove the Markdown code block syntax and parse it as JSON
    if (typeof imagedData === 'string') {
      imagedData = imagedData.replace(/^```json\n|\n```$/gm, '');
      try {
        imagedData = JSON.parse(imagedData);
        // console.log("imagedData Parsed:\n",imagedData);
      } catch (error) {
        console.error("Error parsing JSON:", error);
        return []; // Return an empty array if the JSON is invalid
      }
    }

    // console.log("Prompts imagedData:\n",imagedData);
    const Image_Components = [];
    const components = imagedData.ImageComponents;
  
    for (const [key, value] of Object.entries(components)) {
      // Use optional chaining and provide a default value with nullish coalescing
      const title = value?.Title ?? 'No Title';
      const scenarios = value?.Scenario ?? 'No Scenario';
      
      // Construct the component description with checks
      const component_description = `Component Title: ${title}, ` +
                                    `Component Type: ${value?.Type ?? 'No Type'}, ` +
                                    `Component Description: ${value?.Description ?? 'No Description'}, ` +
                                    `Component Scenario: ${scenarios}, ` +
                                    `Component Data: ${JSON.stringify(value?.Data ?? {})}, ` +
                                    `Component Settings: ${JSON.stringify(value?.Settings ?? {})}`;
      
      Image_Components.push(component_description);
    }
  
    return Image_Components;
  };
  
  
  const handleImageChange = (e) => {
    if (e.target.files.length === 0) {
      setError('No file selected.');
      return; // Exit the function if no file is selected
    }
    
    const file = e.target.files[0];
    if (file.size > 20 * 1024 * 1024) { // Check if the file size is greater than 20 MB
      setError('File size should be less than 20 MB');
      return;
    }
  
    const fileType = file['type'];
    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/webp'];
  
    if (validImageTypes.includes(fileType)) {
      const reader = new FileReader();
  
      // reader.onloadend = () => {
      //   // reader.result contains the base64-encoded string
      //   // Ensure we strip out the data URL part if it's included
      //   const base64String = reader.result.replace(/^data:.+;base64,/, '');
      //   setSelectedImage(base64String);
      //   console.log("base64String:\n",base64String);
      // };
      reader.onloadend = () => {
        // reader.result contains the base64-encoded string
        // If reader.result includes the data URL part, use it directly
        // Otherwise, prepend the data URL part to the base64 string
        console.log("reader.result:\n",reader.result);
        const base64String = reader.result.startsWith('data:image')
          ? reader.result
          : `data:image/jpeg;base64,${reader.result.replace(/^data:.+;base64,/, '')}`;
        setSelectedImage(base64String);
        console.log("Full Data URL:\n", base64String);
      };
  
      reader.onerror = () => {
        setError('Error occurred while reading the image file.');
      };
  
      reader.readAsDataURL(file); // This will read the file and upon finishing, the onloadend event will contain the base64 string
    } else {
      setError('Please select a valid image file (png, jpeg, webp, gif).');
    }
  };
  
  
// const handleSubmit = async () => {
//     setIsLoading(true);  // Set loading state to true when API call starts
    
//     try {
      
//       const prompt = generatePrompt(userRole, description);
//       // console.log("User Story Prompt:\n"+ prompt)
//       //const message = await callOpenAI(prompt);
//     //   const response = await axios.post('http://localhost:5000/call_OpenAI', {
//     //   prompt: prompt,
//     // });
//     const response = await createChatCompletionGPT4Vision(prompt);

//     const extractedJSON = extractJSONFromText(response);//.data.message); // Assume this function is imported from utilityFunctions.js
//     setOutput(extractedJSON);      

//     //setOutput(message);
//     } catch (error) {
//       console.error("There was an error:", error);
//       setError("There was an error!\n\n"+ error);// Optional: set some state to indicate the error in the UI
      
//     }
    
//     setIsLoading(false); // Reset loading state to false when API call is done
//   };

// const handleSubmit = async () => {
//   setIsLoading(true); // Set loading state to true when API call starts

//   const prompt = generatePrompt(userRole, description);

//   // Function to handle the API response and set the output
//   const handleAPIResponse = async (base64Image) => {
//     try {
//       // Pass the image as a parameter if it's provided, otherwise just send the prompt
//       const response = await createChatCompletionGPT4Vision(prompt, base64Image);
//       console.log("Response:",response);

//       let componentList=createPromptsFromComponents(response);
//      //Need to call response = await createChatCompletionGPT4(prompt); for each item in component list
//       //Then need to combine all the responses into one JSON object
//       //Then need to call extractJSONFromText(response) on the combined JSON object
//       const extractedJSON = extractJSONFromText(response);
//       setOutput(extractedJSON);
//     } catch (error) {
//       console.error("There was an error:", error);
//       setError("There was an error!\n\n" + error);
//     }
//     setIsLoading(false); // Reset loading state to false when API call is done
//   };

//   if (selectedImage) {
//     // If an image is selected, call the API with the image
//     handleAPIResponse(selectedImage);
//   } else {
//     // If no image is selected, call the API without the image
//     handleAPIResponse(null);
//   }
// };

// const handleSubmit = async () => {
//   setIsLoading(true); // Set loading state to true when API call starts
//   setError(null); // Clear any previous errors

//   try {
//     const prompt = generatePrompt(userRole, description);
//     const imageDataResponse = await createChatCompletionGPT4Vision(prompt, selectedImage);
//     // const imagedData = extractJSONFromText(imageDataResponse); // Assuming this extracts and parses the JSON from the response
//     if (imageDataResponse && imageDataResponse.ImageComponents) {
//       // Call the function to create prompts from components
//       const componentPrompts = createPromptsFromComponents(imageDataResponse.ImageComponents);
//       console.log("componentPrompts:\n",componentPrompts);
//       // Process the component prompts here
//       // ...
   
//     let componentList = createPromptsFromComponents(imageDataResponse);
//     let userStories = [];

//     for (const componentDescription of componentList) {
//       const chatCompletionResponse = await createChatCompletionGPT4(generatePrompt(userRole, componentDescription));
//       const extractedStory = extractJSONFromText(chatCompletionResponse); // Assuming this extracts and parses the JSON from the response
//       userStories.push(extractedStory);
//     }

//     // Combine all the user stories into one JSON object
//     const combinedUserStories = {
//       UserStories: userStories
//     };

//     setOutput(combinedUserStories);
//     console.log("combinedUserStories:\n",combinedUserStories);
//   } else {
//     // Handle the case where the response does not contain the expected data
//     console.log("The response does not contain the expected data.");
//     setError('The response does not contain the expected data.');
//   }
//   } catch (error) {
//     console.error("There was an error:", error);
//     setError(`There was an error! ${error.message}`);
//   }

//   setIsLoading(false); // Reset loading state to false when API call is done
// };

// const jsonValuesToString = (jsonObj) => {
//   return Object.entries(jsonObj).map(([key, value]) => `${key}: ${value}`).join('\n\n');
// };

const jsonValuesToString = (jsonObj, indentLevel = 0) => {
  const indent = '  '.repeat(indentLevel);
  let result = '';

  for (const [key, value] of Object.entries(jsonObj)) {
    if (Array.isArray(value)) {
      // If the value is an array, iterate over the elements.
      result += `${indent}${key}:\n${value.map((item) => `${indent}  ${item}`).join('\n')}\n\n`;
    } else if (typeof value === 'object' && value !== null) {
      // If the value is a nested object, recursively call the function.
      result += `${indent}${key}:\n${jsonValuesToString(value, indentLevel + 1)}\n`;
    } else {
      // For simple key-value pairs, add them to the result.
      result += `${indent}${key}: ${value}\n\n`;
    }
  }

  return result.trim(); // Trim the final result to remove any extra whitespace.
};

// Use this function to convert and set the initial value for editableOutput
const handleImageDataResponse = (imageDataResponse) => {
  const plainText = jsonValuesToString(imageDataResponse);
  setEditableOutput(plainText);
};


const handleSubmit = async () => {
  setIsLoading(true);
  setError(null);

//Check for Image Selection:
//The code block checks if an image has been selected (selectedImage). If not, it logs to console that no image is selected, sets a message to indicate it's crafting a user story, and then tries to generate a user story without an image.
  if (!selectedImage) {
    console.log("No image selected.");
    setLoaderText('Crafting user story...');
    try {
    
      const prompt = generatePrompt(userRole, description);
  
      const response = await createChatCompletionGPT4(prompt);
  
      const extractedJSON = extractJSONFromText(response); 
      setOutput(extractedJSON);      

    //setOutput(message);
    } catch (error) {
      console.error("There was an error:", error);
      setError("There was an error!\n\n"+ error);// Optional: set some state to indicate the error in the UI
      
    }
    
    setIsLoading(false); // Reset loading state to false when API call is done
    return;
  }

  // Check if the single story toggle is enabled
  if (isSingleStory) {
    // Code to handle a single user story generation
    setEditableOutput(''); // Set the editable output
    setLoaderText('Analysing Image...');
    setCount(0); // Reset the counter 
    setOutput(''); // Reset the output

    
    let imageDataResponse = await createChatCompletionGPT4VisionSingleStory(selectedImage);
    // setOutput({ UserStories: [singleUserStory] });

    // If the response is a string, attempt to parse it as JSON
    if (typeof imageDataResponse === 'string') {
      let imagedData = imageDataResponse.replace(/^```json\n|\n```$/gm, '');
      try {
        imagedData = JSON.parse(imagedData);
        console.log("Handle Submit imagedData Parsed:\n",imagedData);
        imageDataResponse = imagedData;
      } catch (error) {
        console.error("Error parsing Image JSON:", error);
        setError('Error parsing the Image response data. Try again. If issue persists, contact support.');
        setIsLoading(false);
        return []; // Return an empty array if the JSON is invalid
      }
    }

    // console.log("imageDataResponse:", imageDataResponse);
    // const extractedJSON = extractJSONFromText(imageDataResponse); // Attempt to parse the JSON response
    // setOutput(imageDataResponse);
    // setEditableOutput(JSON.stringify(imageDataResponse, null, 2)); // Set the editable output

    handleImageDataResponse(imageDataResponse); // Set the editable output by converting JSON to plain text

    setIsLoading(false); // Reset loading state to false when API call is done
    return;
    
  }

  try {
    setLoaderText('Analysing Image...');
    const prompt = generatePrompt(userRole, description);
    console.log("selectedImage:\n",selectedImage);

    
    let imageDataResponse = await createChatCompletionGPT4Vision(selectedImage);

    // Check the type of the response
    // console.log("Type of imageDataResponse:", typeof imageDataResponse);
    
    if (typeof imageDataResponse === 'string') {
      // let imagedData = imageDataResponse.replace(/^```json\n|\n```$/gm, '');
      let imagedData = imageDataResponse.replace(/.*```json\s*/s, '');
      // console.log("imagedData:\n",imagedData);
      imagedData = imagedData.replace(/^```json\n|\n```$/gm, '');
      
      // imagedData = imagedData.replace(/(\}\s*\}\s*\})[\s\S]*/, "$1");
      // console.log("imagedData2:\n",imagedData);

      try {
        imagedData = JSON.parse(imagedData);
        // console.log("Handle Submit imagedData Parsed:\n",imagedData);
        imageDataResponse = imagedData;
      } catch (error) {
        console.error("Error parsing Image JSON:", error);
        setError('Error parsing the Image response data. Try again. If issue persists, contact support.');
        setIsLoading(false);
        return []; // Return an empty array if the JSON is invalid
      }
    }

    
    if (imageDataResponse && imageDataResponse.hasOwnProperty('ImageComponents')) { // Confirm the property name is correct
      setLoaderText('Analysing Image components...');
      const componentPrompts = createPromptsFromComponents(imageDataResponse);
      // console.log("componentPrompts:", componentPrompts);
      
      let userStories = [];
      let retries = 3; // Maximum number of retries for each component
      let i = 1;

    setLoaderText('Crafting ('+componentPrompts.length+') user stories...'); 
    for (const componentDescription of componentPrompts) {
      let attempt = 0;
      let success = false;
      setLoaderText('Crafting  user story ('+i+' of '+componentPrompts.length+')...');  
      while (!success && attempt < retries) {
        try {
          const chatCompletionResponse = await createChatCompletionGPT4(generatePrompt(userRole, componentDescription));
          const extractedStory = extractJSONFromText(chatCompletionResponse); // Attempt to parse the JSON response
          // console.log("User Story Success "+i+":", extractedStory);
          userStories.push(extractedStory);
          success = true; // Set success to true to avoid retries   
        } catch (error) {
          console.error(`Error on attempt ${attempt + 1}:`, error.message);
          if (attempt >= retries - 1) {
            console.error(`Maximum retries reached for component: ${componentDescription}`);
          }
          attempt++; // Increment the attempt counter
        }
      }
      //testing code below
      i = i + 1;
      // if (i >= 2) { // Break the loop after processing two user stories
      //   break;
      // }
    }

    console.log("All User Stories:", userStories);
    setLoaderText('All good! Check out the output below. ');


      const combinedUserStories = { UserStories: userStories };
      setOutput(combinedUserStories);
      console.log("combinedUserStories:", combinedUserStories);
    } else {
      console.log("The response does not contain the expected 'ImageComponents' property.");
      setError('The response does not contain the expected data. Try again. If issue persists, contact support.');
    }
  } catch (error) {
    console.error("There was an error:", error);
    setError(`There was an error! ${error.message}`);
  }

  setIsLoading(false);
};

// const handleFinalizeUserStory = async () => {
//   setIsLoading(true); // Set loading state to true when API call starts
//   setError(null);
//   //code to reset timer to zero
//   setCount(0);
//   setOutput(''); // Reset the output

//   setLoaderText('Crafting user story...');
//     try {
//       console.log("editableOutput:\n",editableOutput);
    
//       // const prompt = generatePrompt(userRole, {description: editableOutput});
//       let prompt = "I am developing a feature for"+ description;
//       prompt = prompt + "Based on the above information, generate a comprehensive user story (or user stories) that include: " +
//       "- A clear title reflecting the user story's intent. " +
//       "- A detailed User Story description. " +
//       "- Craft Acceptance criteria that covers each piece of information provided by using the data in  ContentExamination,Categorization,ContextConsideration,FunctionalRequirements etc. " +
//       "- Edge cases and how they should be handled. " +
//       "- Any necessary technical specifications that will help engineers understand the requirements. " +     
//       "Please format the user story (or stories) in JSON format. The JSON input is: " +editableOutput;

  
//       const response = await createChatCompletionGPT4(prompt);
  
//       const extractedJSON = extractJSONFromText(response); 
//       setOutput(extractedJSON);      

//     //setOutput(message);
//     } catch (error) {
//       console.error("There was an error:", error);
//       setError("There was an error!\n\n"+ error);// Optional: set some state to indicate the error in the UI
      
//     }
    
//     setIsLoading(false); // Reset loading state to false when API call is done
//     return;
  
// };

const MAX_RETRIES = 3; // Maximum number of retries

const handleFinalizeUserStory = async () => {
  setIsLoading(true);
  setError(null);
  setCount(0); // Assuming this is the function to reset the timer to zero
  setOutput('');
  setLoaderText('Crafting user story...');

  let retries = 0;
  let success = false;
  let extractedJSON = null;

  while (!success && retries < MAX_RETRIES) {
    try {
      console.log("editableOutput:\n", editableOutput);
      // Construct your prompt here as before
      let prompt = "I am developing a feature for"+ description+". Target user is "+userRole+".";
      prompt = prompt + "Based on the above information, generate a comprehensive user story (or user stories) that include: " +
      "- A clear title reflecting the user story's intent. " +
      "- A detailed User Story description. " +
      "- Craft Acceptance criteria that covers each piece of information provided by considering the data in  ContentExamination,Categorization,ContextConsideration,FeatureSpecification, FunctionalRequirements etc. " +
      "- Edge cases and how they should be handled. " +
      "- Any necessary technical specifications that will help engineers understand the requirements. " +     
      "Please format the user story (or stories) in JSON format. The JSON input is: " +editableOutput;

      const response = await createChatCompletionGPT4(prompt);
      extractedJSON = extractJSONFromText(response); // If this line throws, the catch block will handle it
      setOutput(extractedJSON);
      success = true; // Set success to true if the above lines execute without throwing
    } catch (error) {
      retries += 1; // Increment retry counter
      console.error(`Attempt ${retries} failed: ${error.message}`);
      setError(`Attempt ${retries} failed: ${error.message}`);
      if (retries < MAX_RETRIES) {
        setLoaderText(`Received invalid response from model. Retrying... (Attempt ${retries + 1} of ${MAX_RETRIES})`);
        await new Promise(resolve => setTimeout(resolve, 1000 * retries)); // Exponential back-off
      }
    }
  }

  if (!success) {
    setError(`Failed to create user story after ${MAX_RETRIES} attempts. Contact support with image used.`);
  }

  setIsLoading(false);
};


  return (
    <div className="user-story-container">
      <h1 className="main-heading">User Story Generator</h1>
      <h3 className="sub-heading">Describe your feature in detail to generate better results.</h3>
      
      <div className="labeldiv">
        <label htmlFor="featuresDescription" className="label">User Story Actor:</label>
      </div>
      <input 
        className="user-story-select"
        type="text"
        placeholder="Enter the Name of the Person or System involved in the User Story"
        onChange={(e) => setUserRole(e.target.value)}
        required
      />
      <div className="labeldiv">
        <label htmlFor="featuresDescription" className="label">Feature description:</label>
      </div>
      
      <textarea
        className="user-story-textarea"
        rows="5"
        onChange={(e) => setDescription(e.target.value)}
        required
      />

      {/* Toggle for signle or Multiple User Story */}
      <div className="toggle-container">
        
        <input 
          id="singleStoryToggle"
          type="checkbox" 
          checked={isSingleStory} 
          onChange={() => setIsSingleStory(!isSingleStory)} 
          style={{ transform: "scale(1.5)", marginRight: "10px" }}
        />
        <label htmlFor="singleStoryToggle" className="label">Check this option if you want to create just one story.</label>
      </div>

      {/* User story file uploader */}
      <div className="user-story-uploader">
        <label htmlFor="userStoryImage" className="label">Image File for User Story:  </label>
        <input 
          id="userStoryImage"
          type="file" 
          accept="image/*" 
          onChange={handleImageChange} 
          required
        />
       
        {/*selectedImage && <img src={`${selectedImage}`} alt="Selected" />*/}
        {selectedImage && <img src={`${selectedImage}`} alt="Selected" style={{ maxWidth: '100%', height: 'auto' }} />}


      </div>

      {/* User story generation button */}
      {!isLoading ? <button 
                      ref={generateButtonRef} // Attach the ref to the button              
                      className="user-story-button" 
                      onClick={handleSubmit}
                      disabled={!userRole || !description || !selectedImage}
                      >Analyze Image</button> : null}
      {/* Add a textarea for editable output */}
      {/* Conditionally render the textarea and finalize button only if there's output */}
      {editableOutput && isSingleStory && (
        <div className="editable-output-container">
          <h3 className="sub-heading">Review and edit the content below (especially Functional Requirements) to refine the user story:</h3>
          <textarea
            className="editable-output-textarea"
            rows="10"
            value={editableOutput}
            onChange={(e) => setEditableOutput(e.target.value)}
          />
          {!isLoading ?<button className="finalize-user-story-button" onClick={handleFinalizeUserStory}>
            Generate User Story
          </button>: null}
        </div>
      )}

     
      <div className="user-story-output" id="output-section">
       
        {isLoading ? (
            <div className="loader-wrapper">
              <div className="loader"></div>
              <div className="loader-text">{count}s {loaderText}</div>
            </div>
        ) : (
            <div className="user-story-output output-container">
                <strong className="output-title">Output:</strong>
                {/*formatOutput(output)*/}
                
                {output && typeof output === 'object' ? 
                            renderJSON(output) : // Render JSON using your utility function
                            error
                        }
                <i className="fas fa-copy copy-icon" onClick={() => copyToClipboard('output-section')}></i>
            </div>
        )}
      </div>
    </div>
  );
}

export default VisionUserStoryComponent;
