import React from 'react';
import OpenAI from 'openai';
import CryptoJS from 'crypto-js';
import '../../components/sortIdeasComponent/ideaSorter.css';
import ReactDOMServer from 'react-dom/server';


// require('dotenv').config();

export const formatOutput = (outputString) => {
  if (!outputString) return null;

  //console.log("Input for Formatting:"+ outputString)
  const sections = outputString.split(/(?:^|\n)(\d+\.\s*|User Story\s*:?|Acceptance Criteria\s*:?|Edge Cases\s*:?|Functional Test Cases\s*:?|Non-Functional Test Cases\s*:?|Edge Case Test Cases\s*:?|Input\s*:?\s*|Expected Output\s*:?)/gi);

  return (
    <div className="formatted-output">
      {sections.map((section, index) => {
        if (section === undefined || section === null) {
          console.error("Undefined or null section detected. Skipping...");
          return null;
        }

        if (index % 2 !== 0) {
          if (section.trim().toLowerCase().startsWith('input') || section.trim().toLowerCase().startsWith('expected output')) {
            return <div className="subsection-title" key={index}><b>{section}</b></div>;
          } else {
            return <div className="section-title" key={index}>{section}</div>;
          }
        }

        const bulletPoints = section ? section.split(/\n-\s*/).slice(1) : [];
        if (bulletPoints.length > 1) {
          return (
            <ul className="bullet-list" key={index}>
              {bulletPoints.map((point, i) => {
                return <li className="bullet-item" key={i}>{point.trim()}</li>;
              })}
            </ul>
          );
        }
        return <div key={index}>{section.trim()}</div>;
      })}
    </div>
  );
};

export const formatOutput2 = (outputString) => {
  if (!outputString) return null;

  //console.log("Input for Formatting:"+ outputString)

  // const sections = outputString.split(/(?:^|\n)(\d+\.\s*|User Story\s*:?|Acceptance Criteria\s*:?|Edge Cases\s*:?|Functional Test Cases\s*:?|Non-Functional Test Cases\s*:?|Edge Case Test Cases\s*:?|Input\s*:?\s*|Expected Output\s*:?)/gi);

  const sections = outputString.split(/(?:^|\n)(\d+\.\s*|Test Case \d+(\s*\([\w\s-]+\))?:?|Actor\s*:?\s*|Action\s*:?\s*|Expected Result\s*:?\s*|Expected Outcome\s*:?\s*)/gi);
 
  //console.log('\nSections output:' + sections)

  let formattedSections = [];
  let isHeader = [];

  // Filter out empty and undefined elements from sections
const filteredSections = sections.filter(section => section !== undefined && section.trim() !== '');

for (let i = 0; i < filteredSections.length; i++) {
  // Skip undefined elements (if any are left)
  if (filteredSections[i] !== undefined) {
    if (/^\d+\.$/.test(filteredSections[i].trim())) {
      if (filteredSections[i + 1] !== undefined) {
        formattedSections.push(filteredSections[i] + " " + filteredSections[i + 1]);
        isHeader.push(false);
      } else {
        console.warn(`Skipped at index ${i} due to undefined next element`);
      }
      i++; // Skip the next section because we have already combined it
    } else {
      formattedSections.push(filteredSections[i]);
      isHeader.push(true);
    }
  }

  }

  return (
    <div className="formatted-output">
      {formattedSections.map((section, index) => {
        if (section === undefined || section === null) {
          console.error("Undefined or null section detected. Skipping...");
          return null;
        }

        if (isHeader[index]) {
          return <div className="section-title" key={index}><b>{section}</b></div>;
        }

        const bulletPoints = section ? section.split(/-\s*/).slice(1) : [];
        if (bulletPoints.length > 1) {
          return (
            <ul className="bullet-list" key={index}>
              {bulletPoints.map((point, i) => {
                return <li className="bullet-item" key={i}>{point.trim()}</li>;
              })}
            </ul>
          );
        }
        return <div key={index}>{section.trim()}</div>;
      })}
    </div>
  );
};


export const copyToClipboard = async (elementId) => {
  const el = document.getElementById(elementId);
  try {
    await navigator.clipboard.writeText(el.textContent || '');
    //console.log('Text successfully copied to clipboard');
  } catch (err) {
    console.error('Failed to copy text: ', err);
  }
};

export const copyToClipboardRichText = async (elementId) => {
  const el = document.getElementById(elementId);
  if (el) {
    try {
      // Use Clipboard API to copy text and HTML to clipboard
      const html = el.outerHTML;
      await navigator.clipboard.write([
        new ClipboardItem({
          'text/plain': el.textContent,
          'text/html': html,
        }),
      ]);
      //console.log('Text and HTML successfully copied to clipboard');
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  } else {
    console.error('Element with the given ID not found.');
  }
};


export const extractJSONFromText = (inputText) => {

  //console.log('\nextractJSONFromText JSON Input Text:' + inputText)

  const jsonStartIndex = inputText.indexOf('{');
  const jsonEndIndex = inputText.lastIndexOf('}') + 1;
  
  if (jsonStartIndex === -1 || jsonEndIndex === 0) {
    throw new Error('No JSON object found in the input text.');
  }
  
  let extractedJsonString = inputText.substring(jsonStartIndex, jsonEndIndex);

  let cleanedString;


  //console.log('\nJSON EXTRACTED Input Text:' + extractedJsonString)
  try {
    const parsedJson = JSON.parse(extractedJsonString);
    //console.log('\nJSON Output Text:' + parsedJson)

    return parsedJson;
  } catch (error) {
    throw new Error(`Failed to parse JSON: ${error.message}`);
  }
};


// export const renderJSON = (jsonObject) => {
  
//   const renderValue = (key, value) => {
//       if (typeof value === 'string') {
//           return <span>{value}</span>;
//       } else if (Array.isArray(value)) {
//           return (
//               <ul>
//                   {value.map((item, index) => <li key={index}>{renderValue(null, item)}</li>)}
//               </ul>
//           );
//       } else if (typeof value === 'object') {
//           return renderJSON(value);
//       } else {
//           return <span>{value}</span>;
//       }
//   };

//   return (
//       <div style={{marginLeft: '20px', textAlign: 'left'}}>
//           {Object.keys(jsonObject).map((key, index) => (
//               <div key={index}>
//                   <strong>{key}:</strong> {renderValue(key, jsonObject[key])}
//               </div>
//           ))}
//       </div>
//   );
// };

export const renderJSON = (jsonObject) => {

  const renderValue = (key, value) => {
      if (typeof value === 'string') {
          return <span className="json-value">{value}</span>;
      } else if (Array.isArray(value)) {
          return (
              <ul className="json-array">
                  {value.map((item, index) => <li className="json-array-item" key={index}>{renderValue(null, item)}</li>)}
              </ul>
          );
      } else if (typeof value === 'object') {
          return renderJSON(value);
      } else {
          return <span className="json-other">{value}</span>;
      }
  };

  return (
      <div className="json-object" style={{marginLeft: '20px', textAlign: 'left'}}>
          {Object.keys(jsonObject).map((key, index) => (
              <div className="json-key-value" key={index}>
                  <strong className="json-key">{key}:</strong> {renderValue(key, jsonObject[key])}
              </div>
          ))}
      </div>
  );
}


export const RenderTable = ({ sortedIdeas }) => {
  let innerSortedIdeas;

  //console.log("\nInside RenderTable:" + typeof sortedIdeas)
  // Check the type of sortedIdeas
  if (typeof sortedIdeas === "string") {
    try {
      innerSortedIdeas = JSON.parse(sortedIdeas);
    } catch (e) {
      console.error("Error parsing sortedIdeas string:", e);
      return <div>Error in rendering table.</div>;
    }
  } else if (typeof sortedIdeas === "object" && Array.isArray(sortedIdeas.sortedIdeas)) {
    innerSortedIdeas = sortedIdeas.sortedIdeas || [];
  } else {
    return <div>Invalid sortedIdeas type.</div>;
  }

  const firstItemKeys = innerSortedIdeas.length ? Object.keys(innerSortedIdeas[0]) : [];

  return (
    <table className='ideaSorter-table' border="1">
      <thead className='ideaSorter-thead'>
        <tr className='ideaSorter-tr'>
          {firstItemKeys.map((key, index) => (
            <th className='ideaSorter-th' key={index}>{key}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {innerSortedIdeas.map((item, index) => (
          <tr key={index} className='ideaSorter-tr'>
            {firstItemKeys.map((key, idx) => (
              <td className='ideaSorter-td' key={idx}>
                {Array.isArray(item[key]) ? (
                  <ul>
                    {Object.values(item[key]).map((idea, ideaIdx) => (
                      <li key={ideaIdx}>{idea}</li>
                    ))}
                  </ul>
                ) : (
                  item[key]
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};





export const RenderTable2 = ({ sortedIdeas }) => {
  let innerSortedIdeas;
  //console.log("\nInside RenderTable2")
  // Check the type of sortedIdeas
  if (typeof sortedIdeas === "string") {
    try {
      innerSortedIdeas = JSON.parse(sortedIdeas);
    } catch (e) {
      console.error("Error parsing sortedIdeas string:", e);
      return <div>Error in rendering table.</div>;
    }
  } else if (Array.isArray(sortedIdeas)) {
    // Check if the "Ideas" field is an array of objects and convert it if necessary
    innerSortedIdeas = sortedIdeas.map(item => {
      if (Array.isArray(item.Ideas)) {
        // If "Ideas" is an array of objects, extract the "Ideas" key and convert it to a single string with semicolons
        const ideasArray = item.Ideas.map(idea => idea.Ideas);
        item.Ideas = ideasArray.join("; ");
      }
      return item;
    });
  } else if (typeof sortedIdeas === "object"){
       return RenderTable(sortedIdeas);
  }else {
    return <div>Invalid sortedIdeas type.</div>;
  }

  const firstItemKeys = innerSortedIdeas.length ? Object.keys(innerSortedIdeas[0]) : [];
  
  const renderCell = (item, key) => {
    if (Array.isArray(item[key])) {
      return (
        <ul>
          {item[key].map((subItem, subIdx) => (
            <li key={subIdx}>{JSON.stringify(subItem)}</li>
          ))}
        </ul>
      );
    } else if (typeof item[key] === 'object') {
      return (
        <ul>
          {Object.keys(item[key]).map(subKey => (
            <li key={subKey}>{` ${item[key][subKey]}`}</li>
          ))}
        </ul>
      );
    }
    return item[key];
  };

  return (
    <table border="1" className='ideaSorter-table'>
      <thead className='ideaSorter-thead'>
        <tr className='ideaSorter-tr'>
          {firstItemKeys.map((key, index) => <th key={index}>{key}</th>)}
        </tr>
      </thead>
      <tbody>
        {innerSortedIdeas.map((item, index) => (
          <tr key={index} className='ideaSorter-tr'>
            {firstItemKeys.map((key, idx) => (
              <td className='ideaSorter-td' key={idx}>
                {renderCell(item, key)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

//Reading KEY from Browser cache and returning the decrypted key
export const readAndDecryptKey = () => {
  // Fetch the encrypted key from localStorage
  const encryptedKey = localStorage.getItem('encryptedKey');

  if (!encryptedKey) {
    console.log('No key found in local storage.');
    return null;
  }

  // Decrypt the key using AES decryption
  const decryptedKeyBytes = CryptoJS.AES.decrypt(encryptedKey, 'cc2df04b-8761-4dfb-9fac-d8c1dd6c7caa');
  const decryptedKey = decryptedKeyBytes.toString(CryptoJS.enc.Utf8);

  //console.log('Decrypted Key:', decryptedKey); // For debugging purposes, remove this line in production

  return decryptedKey;
};

//Flesch-Kincaid Readability Guidelines
const rulesForFleschKincaid = () => {
  let rules = '1. Shorter Sentences: Limit the sentence length to max of 20 words.'

rules += '2. Choose Simple Words: Use common and straightforward vocabulary. Avoid jargon, acronyms, or complex terminology unless absolutely necessary.'

rules += '3. Favor Active Voice: Utilize active voice for clearer and more direct statements. Minimize the use of passive voice to enhance readability.'

rules += '4. Limit Syllables: Aim for words with fewer syllables. A lower average syllable count per word like 4 syllables can make the text easier to read.'

rules += '5. Consistent Tense and Structure: Maintain consistency in tense and sentence structure to avoid confusing the reader.'

rules += '6. Paragraph Breaks: Use short paragraphs to separate ideas and make the text more visually digestible. Aim for 4-5 sentences per paragraph.'

rules += '7. Transitional Words: Use transitional words or phrases like "however," "therefore," and "for example" to guide the reader through the text, but do not overuse them.'

rules += '8. Personal Pronouns: Include personal pronouns like "you" and "we" to make the text more relatable and easier to understand.'

rules += '9. Clarify Pronoun References: Ensure that it\'s clear to whom or what your pronouns are referring to avoid ambiguity.'


return rules;
}

//Tone and Rehoric Rules

const rulesForToneAndRhetoric1 = () => {
  let toneRules = '\n1. Do not omit articles such as "a", "the"'
  toneRules += '\n2. Do not change the MEANING OF WORDS. for example, "Added" should not be changed to "Inserted"'
  toneRules += '\n3. Retain titles in text as it is. Do not change the tiltes. for example Input: \nAttendance Extract (Early, End of Year, Sep 30) Version: 3.9 \n Per MSDE, the Geolocation ID is added to the Attendance Extract (Sep 30). \n Output: \nAttendance Extract (Early, End of Year, Sep 30) Version: 3.9\nAccording to MSDE, the Attendance Extract (Sep 30) now includes the Geolocation ID.'
  toneRules +='\n4. Use "per [Authority\'s Name]" when you want to emphasize that an action, decision, or piece of information is in strict compliance with the guidelines, policies, or directives issued by a specific authority.\nExamples:\n  Correct: "Per the latest guidelines from the CDC, all employees are required to wear masks indoors."\n Incorrect: "According to the latest guidelines from the CDC, all employees are required to wear masks indoors."'
  toneRules +='\n5. Avoid Overly Formal Language: Aim for clarity and directness without sacrificing professionalism. Overly formal language can make the text less accessible and harder to understand. Instead of using phrases like \"in accordance with,\" \"it is crucial that,\" or \"for the purpose of,\" opt for simpler alternatives.\n  Examples:\n  Overly Formal: \"In accordance with our organization\'s rules, you are required to...\"\n  Preferred: \"According to our rules, you need to...\"\n Overly Formal: \"It is crucial that you update your systems...\"\n  Preferred: \"Make sure to update your systems...\"\n  Overly Formal: \"For the purpose of maintaining a suitable work environment...\"\n  Preferred: \"To keep a good work environment...\"'
  toneRules +='\n6. Try to avoid using adverbs where possible. For example: Avoid "too relaxed", "Always"'
  

  return toneRules;
}

const rulesForToneAndRhetoric2 = () => {
  let toneRules = '\n1. Use active voice as much as possible. Incorrect: A new value should be entered into the field. Correct: Enter a new value into the field. Be Cautious with Active Voice: Utilize active voice for clarity and directness, but only when the agent performing the action is both clear and accurate. If using active voice risks misrepresenting the agent of the action, consider using passive voice or explicitly specify the correct agent. For example, if a user action triggers the report, then stating "You can generate a new report" may be more accurate than saying "The system generates a new report."';
  toneRules += '\n2. Avoid gendered language (he/she, his/her). Use gender-neutral language (the teacher, the student).'
  toneRules += '\n3. Avoid overly formal language such as in order to, it is essential that, on the basis of, as per rules, and so on.'
  toneRules += '\n4. Do not use contractions (it\'s, don\'t, won\'t, can\'t, we\'ve,That\'s, we should, etc.) unless you are quoting a software element that contains contractions.'
  toneRules += '\n5. Be mindful of the connotative meanings of words. For example, the word cheap has a negative connotation, whereas inexpensive has a positive connotation.'
  toneRules += '\n6. Avoid using Please and inverted commas or emphasis.'
  toneRules += '\n7. Retain the original meaning while converting to present tense'
  toneRules += '\n8. Avoid using adjectives like excited, pleased, etc'
  toneRules += '\n9. Avoid using marketting lanuage like we are presenting to you, happy to let you know, etc.'
  toneRules += '\n10. Avoid using quotation marks for emphasis. For example avoid terms like "casual Fridays" or "the so-called experts"'
  
  

  return toneRules;
}

//Words usage Rules
const rulesForWordUsage = () => {
  let wordRules;
  wordRules='ampersand: Do not use the & symbol (ampersand) in place of the word and.';
  wordRules+='\nabbreviations: Do not use abbreviations such as etc., i.e., e.g., and so on.';
  wordRules+='\ncontractions: Do not use contractions (don\'t, won\'t, can\'t, we\'ve, etc.) unless you are quoting a software element that contains contractions.';
  wordRules += '\nformal language: Avoid overly formal language such as in order to, it is essential that, on the basis of, and so on.';
  wordRules += '\nnumbers: Do not use numbers to start a sentence. Spell out the number or rephrase the sentence.';
  wordRules += 'greater than: Do not use to denote a click path (navigation). Always use words to provide navigational instructions.'
  wordRules += '\naccess, accessible: Reserve these words to refer to the accessibility of the software. Avoid using these words to describe opening the software or in terms of security access.';
  wordRules += '\nalien: Use Alternatives such as immigrant, or noncitizen.';
  wordRules += '\nappear: Use Alternatives such as review,access learn about,read,refer,display.';

  return wordRules;

}

//OPEN AI API CALLS

export async function createChatCompletion(prompt) {
  try {
    // let openai = undefined;
    const OPENAI_API_KEY = readAndDecryptKey()
    //console.log("\nThe OPENAI_KEY:"+ OPENAI_API_KEY) //process.env.OPENAI_API_KEY)
    // const openai = new OpenAI(OPENAI_API_KEY);
    const openai =new OpenAI({ apiKey: OPENAI_API_KEY,dangerouslyAllowBrowser: true });
    
    
    
    //console.log("\nThe after initalization:"+ prompt);
  

    // let aiResponse = await openai.createChatCompletion({
    //   model: 'gpt-4',
    //   messages: prompt,
    //   temperature: 0.5,
    // });

    const System_Input = "As a Product Manager Assistant, I need to provide helpful, respectful, and honest assistance while ensuring user safety and a positive experience. Functional Requirements: Helpfulness: Always provide the most useful and accurate information. Respectfulness: Interact in a courteous and respectful manner. Honesty: If uncertain about an answer, admit it rather than providing false information. Non-Functional Requirements Safety: Avoid sharing any harmful, unethical, racist, sexist, toxic, dangerous, or illegal content. Social Unbiasedness: Ensure responses are socially unbiased. Positive Nature: Maintain a positive tone in all interactions. Acceptance Criteria Answer Quality: Answers should be factually accurate and helpful. Tone: Answers should be polite and positive. Safety: No harmful or biased content should be included in the answers. Error Handling: Clearly explain if a question is not factually coherent instead of providing incorrect information.Edge Cases Ambiguous Questions: Provide clarification for questions that are not clear. Sensitive Topics: Tread carefully while providing information on socially sensitive topics. Complex Queries: If a question involves multiple facets, break down the answer into understandable parts. " 

    const chatCompletion = await openai.chat.completions.create({
      model: "gpt-3.5-turbo-16k",      
      messages: [
        { role: "system", content: System_Input },
        { role: "user", content: prompt }       
      ],
    });

    // result = aiResponse.data.choices[0].message['content'];
    const result = chatCompletion.choices[0].message.content;
    //console.log("\nThe AI Response:"+result)
    return result;
  } catch (error) {
    console.error("Error creating chat completion:", error);
  }
};

export async function createChatCompletionGPT4(prompt) {
  try {
    // let openai = undefined;
    const OPENAI_API_KEY = readAndDecryptKey()
    const openai =new OpenAI({ apiKey: OPENAI_API_KEY,dangerouslyAllowBrowser: true });  
      

    

    const System_Input = "As a Product Manager Assistant, I need to provide helpful, respectful, and honest assistance while ensuring user safety and a positive experience. Functional Requirements: Helpfulness: Always provide the most useful and accurate information. Respectfulness: Interact in a courteous and respectful manner. Honesty: If uncertain about an answer, admit it rather than providing false information. Non-Functional Requirements Safety: Avoid sharing any harmful, unethical, racist, sexist, toxic, dangerous, or illegal content. Social Unbiasedness: Ensure responses are socially unbiased. Positive Nature: Maintain a positive tone in all interactions. Acceptance Criteria Answer Quality: Answers should be factually accurate and helpful. Tone: Answers should be polite and positive. Safety: No harmful or biased content should be included in the answers. Error Handling: Clearly explain if a question is not factually coherent instead of providing incorrect information.Edge Cases Ambiguous Questions: Provide clarification for questions that are not clear. Sensitive Topics: Tread carefully while providing information on socially sensitive topics. Complex Queries: If a question involves multiple facets, break down the answer into understandable parts. If user story, Give proper user story title.\n"  

    const chatCompletion = await openai.chat.completions.create({
      model: "gpt-4",      
      messages: [
        { role: "system", content: System_Input },
        { role: "user", content: prompt }       
      ],
    });

    // result = aiResponse.data.choices[0].message['content'];
    const result = chatCompletion.choices[0].message.content;
    //console.log("\nThe AI Response:"+result)
    return result;
  } catch (error) {
    console.error("Error creating chat completion:", error);
  }
}

// export async function ideasPrioritizationGPT4(prompt) {

//   const maxRetries = 3; // Set the maximum number of retries
//   let currentRetry = 0;

//   try {
//     // let openai = undefined;
//     const OPENAI_API_KEY = readAndDecryptKey()
//     const openai =new OpenAI({ apiKey: OPENAI_API_KEY,dangerouslyAllowBrowser: true });  
    

//     const jsonData = [
//       {
//         "Idea": "XXXX",
//         "R": "X",
//         "Explanation for R": "XXXXX",
//         "I": "X",
//         "Explanation for I": "XXXXX",
//         "C": "X",
//         "Explanation for C": "XXXXX",
//         "E": "X",
//         "Explanation for E": "XXXXX",
//         "RICE Score": "XX",
//         "Kano Model Classification": "XXXXX",
//         "Explanation for Kano Classification": "XXXXX"
//       },
       
//     ];

//     const System_Input = "As a Product Manager Assistant, I need to provide helpful, respectful, and honest assistance while ensuring user safety and a positive experience. Functional Requirements: Helpfulness: Always provide the most useful and accurate information. Honesty: If uncertain about an answer, admit it rather than providing false information. Non-Functional Requirements Safety: Avoid sharing any harmful, unethical, racist, sexist, toxic, dangerous, or illegal content. Positive Nature: Maintain a positive tone in all interactions. Tone: Answers should be polite and positive. Error Handling: Clearly explain if a question is not factually coherent instead of providing incorrect information. Edge Cases and Ambiguous Questions: Provide clarification for questions that are not clear. Sensitive Topics: Tread carefully while providing information on socially sensitive topics. Complex Queries: If a question involves multiple facets, break down the answer into understandable parts. Proviede answer in this JSON format:\n" + jsonData;



//     const chatCompletion = await openai.chat.completions.create({
//       model: "gpt-4",      
//       messages: [
//         { role: "system", content: System_Input },
//         { role: "user", content: prompt }       
//       ]
//       // response_format :{ "type": "json_object" }
//     });

//     // result = aiResponse.data.choices[0].message['content'];
//     const result = chatCompletion.choices[0].message.content;
//     console.log("\nThe AI Response:"+result)

//     let prioritizedData =''
//     if (typeof result === 'string') {
//       console.log("imagedData is a string");
//       prioritizedData = result.replace(/^```json\n|\n```$/gm, '');
//       try {
//         prioritizedData = JSON.parse(prioritizedData);
//         // console.log("imagedData Parsed:\n",imagedData);
//       } catch (error) {
//         console.error("Error parsing JSON:", error);
//         return []; // Return an empty array if the JSON is invalid
//       }
//     } else {
//       console.log("imagedData is a:", typeof result);
//       prioritizedData = result;
//     }

//     // if (!Array.isArray(prioritizedData)) {
//     //   console.error("Expected an array but received:", typeof prioritizedData);
//     //   // Handle this case appropriately
//     // } else {
//     //   const prioritizedIdeas = renderIdeasTable(prioritizedData);
//     //   return prioritizedIdeas;
//     // }

//     // Assuming 'prioritizedData' is your JSON object
//     console.log("Type of prioritizedData:", typeof prioritizedData);
//     console.log("Content of prioritizedData:", prioritizedData);

// // Check if 'prioritizedData' has the 'Ideas' key and it's an array
//     if (prioritizedData && Array.isArray(prioritizedData.Ideas)) {
//       const prioritizedIdeas = renderIdeasTable(prioritizedData.Ideas);
//       return prioritizedIdeas;
//     } else {
//       console.error("Expected an array in 'Ideas', but received:", typeof prioritizedData.Ideas);
//       // Handle the case where 'Ideas' is not an array or doesn't exist
//     }

//     // console.log("\nThe prioritizedData:\n"+prioritizedData)
//     // const prioritizedIdeas = renderIdeasTable(prioritizedData);

//     // return prioritizedIdeas;

//   } catch (error) {
//     console.error("Error in prioritizing ideas:", error);
//   }
// }

export async function ideasPrioritizationGPT4(prompt) {
  const maxRetries = 3; // Set the maximum number of retries
  let currentRetry = 0;
  const OPENAI_API_KEY = readAndDecryptKey();
  const openai = new OpenAI({ apiKey: OPENAI_API_KEY, dangerouslyAllowBrowser: true });

  const jsonData ={ "Ideas":[
          {
            "Idea": "XXXX",
            "R": "X",
            "Explanation_R": "XXXXX",
            "I": "X",
            "Explanation_I": "XXXXX",
            "C": "X",
            "Explanation_C": "XXXXX",
            "E": "X",
            "Explanation_E": "XXXXX",
            "RICE Score": "XX",
            "Kano_Model": "XXXXX",
            "Kano_Explanation": "XXXXX"
          }
           
        ]};
    
  const System_Input = "As a Product Manager Assistant, I need to provide helpful, respectful, and honest assistance while ensuring user safety and a positive experience. Functional Requirements: Helpfulness: Always provide the most useful and accurate information. Honesty: If uncertain about an answer, admit it rather than providing false information. Non-Functional Requirements Safety: Avoid sharing any harmful, unethical, racist, sexist, toxic, dangerous, or illegal content. Positive Nature: Maintain a positive tone in all interactions. Tone: Answers should be polite and positive. Error Handling: Clearly explain if a question is not factually coherent instead of providing incorrect information. Edge Cases and Ambiguous Questions: Provide clarification for questions that are not clear. Sensitive Topics: Tread carefully while providing information on socially sensitive topics. Complex Queries: If a question involves multiple facets, break down the answer into understandable parts. MUST Provide answer in this JSON format:\n" + + JSON.stringify(jsonData, null, 2);

  // console.log("\nprompt:\n"+ prompt + "Response MUST be in this JSON FORMAT:" + + JSON.stringify(jsonData, null, 2))
  // console.log("\nprompt:\n" + prompt + "\nResponse MUST be in this JSON FORMAT:\n" + JSON.stringify(jsonData, null, 2));
  prompt = prompt + "\nResponse MUST be in this JSON FORMAT:\n" + JSON.stringify(jsonData, null, 2);
  console.log("\nprompt:\n" + prompt );
  while (currentRetry < maxRetries) {
    try {
      const chatCompletion = await openai.chat.completions.create({
        model: "gpt-4",
        messages: [
          { role: "system", content: System_Input }, // Adjusted for clarity
          { role: "user", content: prompt+ "Response MUST be in this JSON FORMAT:" + jsonData }     
        ]
      });

      const result = chatCompletion.choices[0].message.content;
      console.log("\nThe AI Response:", result);

      let prioritizedData = '';
      if (typeof result === 'string') {
        // console.log("Received data is a string");
        prioritizedData = result.replace(/^.*?```\n|\n```$/gm, '');

        try {
          prioritizedData = JSON.parse(prioritizedData);
          // console.log("Type of prioritizedData after JSON.Parse:", typeof prioritizedData);

          let ideasArray;
          if (prioritizedData.Ideas && Array.isArray(prioritizedData.Ideas)) {
            console.log("Ideas array found");
            ideasArray = prioritizedData.Ideas;
          } else if (prioritizedData["Prioritized Ideas"] && Array.isArray(prioritizedData["Prioritized Ideas"])) {
            console.log("Prioritized Ideas array found");            
            ideasArray = prioritizedData["Prioritized Ideas"].map(item => {
              return { ...item, RICE: item["RICE Model"][0], KANO: item["KANO Model"], KANO_Reason: item["KANO_Reason"] };
            });
          } else if (prioritizedData.ideas && Array.isArray(prioritizedData.ideas)) {
            console.log("ideas array found");
            ideasArray = prioritizedData.ideas.map(idea => {
              const riceObject = idea.RICE.reduce((acc, cur) => ({...acc, ...cur}), {});
              return { ...idea, RICE: riceObject };
            });
          } else {
            throw new Error("JSON parsed but not in expected format");
          }

          const prioritizedIdeas = renderIdeasTable(ideasArray);
          // console.log("Type of prioritizedIdeas:", typeof prioritizedIdeas);
          // console.log("Content of prioritizedIdeas:", prioritizedIdeas);
          return prioritizedIdeas;
        } catch (parseError) {
          console.error("Error parsing JSON:", parseError);
          currentRetry++;
          if (currentRetry >= maxRetries) {
            throw new Error("Maximum retries reached for JSON parsing");
          }
        }
      } else {
        console.error("Received data is not a string:", typeof result);
        currentRetry++;
        if (currentRetry >= maxRetries) {
          throw new Error("Maximum retries reached, received data is not a string");
        }
      }
    } catch (error) {
      console.error("Error in prioritizing ideas:", error);
      return "Received an invalid response while prioritizing ideas. Please try again."; // Return an empty array or handle the error as required
    }
  }
}

 

// export const renderIdeasTable = (jsonData) => {
//   return (
//     <table style={{ width: '100%', borderCollapse: 'collapse' }}>
//       <thead>
//         <tr>
//           <th>Idea</th>
//           <th>R (Reach)</th>
//           <th>I (Impact)</th>
//           <th>C (Confidence)</th>
//           <th>E (Effort)</th>
//           <th>RICE Score</th>
//           <th>Kano Model Class</th>
//           <th>Kano Model Explanation</th>
//         </tr>
//       </thead>
//       <tbody>
//         {jsonData.map((idea, index) => (
//           <tr key={index}>
//             <td>{idea.Idea}</td>
//             <td>{idea.R} - {idea["Rationale R"]}</td>
//             <td>{idea.I} - {idea["Rationale I"]}</td>
//             <td>{idea.C} - {idea["Rationale C"]}</td>
//             <td>{idea.E} - {idea["Rationale E"]}</td>
//             <td>{idea["RICE Score"]}</td>
//             <td>{idea["Kano Model"]}</td>
//             <td>{idea["Rationale Kano Model"]}</td>
//           </tr>
//         ))}
//       </tbody>
//     </table>
//   );
// };




// export const renderIdeasTable = (jsonData) => {
//   const table = (
//     <table style={{ width: '100%', borderCollapse: 'collapse' }}>
//       <thead>
//         <tr>
//           <th>Idea</th>
//           <th>R (Reach)</th>
//           <th>I (Impact)</th>
//           <th>C (Confidence)</th>
//           <th>E (Effort)</th>
//           <th>RICE Score</th>
//           <th>Kano Model Class</th>
//           <th>Kano Model Explanation</th>
//         </tr>
//       </thead>
//       <tbody>
//         {jsonData.map((idea, index) => (
//           <tr key={index}>
//             <td>{idea.Idea}</td>
//             <td>{idea.R} - {idea["Rationale R"]}</td>
//             <td>{idea.I} - {idea["Rationale I"]}</td>
//             <td>{idea.C} - {idea["Rationale C"]}</td>
//             <td>{idea.E} - {idea["Rationale E"]}</td>
//             <td>{idea["RICE Score"]}</td>
//             <td>{idea["Kano Model"]}</td>
//             <td>{idea["Rationale Kano Model"]}</td>
//           </tr>
//         ))}
//       </tbody>
//     </table>
//   );

//   // Render the JSX to a string using ReactDOMServer
//   const tableString = ReactDOMServer.renderToStaticMarkup(table);

//   console.log("Table String:", tableString);

//   return tableString;
// };

export const renderIdeasTable = (jsonData) => {
  // Function to safely get value with fallback keys
  const getValue = (obj, primary, fallbacks = []) => {
    if (obj.hasOwnProperty(primary)) {
      return obj[primary];
    }
    for (let key of fallbacks) {
      if (obj.hasOwnProperty(key)) {
        return obj[key];
      }
    }
    return ''; // Return an empty string if none of the keys are found
  };

  const table = (
    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
      <thead style={{ border: '2px solid black' }}>
        <tr>
          <th style={{ border: '1px solid black' }}>Idea</th>
          <th style={{ border: '1px solid black' }}>R (Reach)</th>
          <th style={{ border: '1px solid black' }}>I (Impact)</th>
          <th style={{ border: '1px solid black' }}>C (Confidence)</th>
          <th style={{ border: '1px solid black' }}>E (Effort)</th>
          <th style={{ border: '1px solid black' }}>RICE Score</th>
          <th style={{ border: '1px solid black' }}>Kano Model Class</th>
          <th style={{ border: '1px solid black' }}>Kano Model Explanation</th>
        </tr>
      </thead>
      <tbody>
        {jsonData.map((idea, index) => (
          <tr key={index}>
            <td style={{ textAlign: 'left', border: '1px solid black' }}>{getValue(idea, 'Idea')}</td>
            <td style={{ textAlign: 'left', border: '1px solid black' }}>{getValue(idea, 'R')} - {getValue(idea, 'Rationale R', ['ReachReason','R_Justification','Rationale for R','Explanation_R','R_explanation',,'R_Explanation','ReasonR','ExplanationR'])}</td>
            <td style={{ textAlign: 'left', border: '1px solid black' }}>{getValue(idea, 'I')} - {getValue(idea, 'Rationale I', ['ImpactReason','I_Justification','Rationale for I','Explanation_I','I_explanation','I_Explanation','ReasonI','ExplanationI'])}</td>
            <td style={{ textAlign: 'left', border: '1px solid black' }}>{getValue(idea, 'C')} - {getValue(idea, 'Rationale C', ['ConfidenceReason','C_Justification','Rationale for C','Explanation_C','C_explanation','C_Explanation','ReasonC','ExplanationC'])}</td>
            <td style={{ textAlign: 'left', border: '1px solid black' }}>{getValue(idea, 'E')} - {getValue(idea, 'Rationale E', ['EffortReason','E_Justification','Rationale for E','Explanation_E','E_explanation','E_Explanation','ReasonE','ExplanationE'])}</td>
            <td style={{ textAlign: 'left', border: '1px solid black' }}>{getValue(idea, 'RICE Score', ['RICE','RICE_Score','RICE Score','RICEScore'])}</td>
            <td style={{ textAlign: 'left', border: '1px solid black' }}>{getValue(idea, 'Kano Model', ['Kano','Kano_Model','Kano Model','KanoModel','KANO'])}</td>
            <td style={{ textAlign: 'left', border: '1px solid black' }}>{getValue(idea, 'Rationale Kano Model', ['KanoReason','Kano_Explanation','Rationale for Kano','Explanation_Kano_Model','KanoExplanation','KANO_Reason'])}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  // Render the JSX to a string using ReactDOMServer
  const tableString = ReactDOMServer.renderToStaticMarkup(table);

  // console.log("Table String:", tableString);

  return tableString;
};



export async function createChatCompletionGPT4Vision(base64_image) {
  try {
    // let openai = undefined;
    // console.log("\nInside createChatCompletionGPT4Vision:", base64_image);
    const OPENAI_API_KEY = readAndDecryptKey()
    const openai =new OpenAI({ apiKey: OPENAI_API_KEY,dangerouslyAllowBrowser: true });  
      

    

    const System_Input = `As a Product Manager Assistant, I need you to identify the components of the image and output in the following JSON format: {
      "ImageComponents": {
        "Component1": {
          "Title": "Component 1 Title",
          "Type": "Widget or Component Type",
          "Data": {
            "Metric1": "Value",
            "Metric2": "Value"            
          },
          "Settings": {
            "settings1": "Name of Setting, its value",
            "PermissionLevel": "Role required to view or interact"            
          },
          "Scenario": {
            "Scenario1": "Usage Secnario Description"            
          },
        },
        "Component2": {
           
        }
         
      }
    }\n. Also identify all the text data in the image and output in the JSON format.`;
    

     // Initialize messages with a system message
     let messages = [
      { role: "system", content: System_Input },
      {
        "role": "user",
        "content": [
          {
            "type": "text",
            "text": "Output ALL the components of the image. Make them logical. "
          },
          {
            "type": "image_url",
            "image_url": {
              "url": base64_image
            }
          }
        ]
      }
      
    ];

    const chatCompletion = await openai.chat.completions.create({
      model: "gpt-4-vision-preview", 
      // response_format:  {"type": "json"},   
      max_tokens: 3800,  
      "messages": messages,
    });

    // // result = aiResponse.data.choices[0].message['content'];
    // const result1 = chatCompletion.choices[0].message.content;
    // // Append the assistant's reply to the messages
    // messages.push({ role: "assistant", content: result1 });
    // console.log(result1);

    // // User's follow-up question for Word  rule evaluation
    // const System_Input2 = "As a Product Manager Assistant, I need to provide helpful, respectful, and honest assistance while ensuring user safety and a positive experience. Functional Requirements: Helpfulness: Always provide the most useful and accurate information. Respectfulness: Interact in a courteous and respectful manner. Honesty: If uncertain about an answer, admit it rather than providing false information. Non-Functional Requirements Safety: Avoid sharing any harmful, unethical, racist, sexist, toxic, dangerous, or illegal content. Social Unbiasedness: Ensure responses are socially unbiased. Positive Nature: Maintain a positive tone in all interactions. Acceptance Criteria Answer Quality: Answers should be factually accurate and helpful. Tone: Answers should be polite and positive. Safety: No harmful or biased content should be included in the answers. Error Handling: Clearly explain if a question is not factually coherent instead of providing incorrect information.Edge Cases Ambiguous Questions: Provide clarification for questions that are not clear. Sensitive Topics: Tread carefully while providing information on socially sensitive topics. Complex Queries: If a question involves multiple facets, break down the answer into understandable parts.\n" 
    // let messages2 = [
    //   { role: "system", content: System_Input2 + "Output in JSON Format." }      
    // ];
    // messages2.push({ role: "user", content: prompt });
    

    // // Second call to OpenAI API with the updated messages
    // let chatCompletion2 = await openai.chat.completions.create({
    //   model: "gpt-4",
    //   // response_format :{ "type": "json_object" },
    //   messages: messages2
      
    // });
    // //console.log("\nThe AI Response:"+result)
    const result = chatCompletion.choices[0].message.content;
    // console.log("\nInside createChatCompletionGPT4Vision Result:", result);
    return result;
  } catch (error) {
    console.error("Error creating chat completion:", error);
  }
}
export async function createChatCompletionGPT4VisionSingleStory(base64_image) {
  try {
    // let openai = undefined;
    // console.log("\nInside createChatCompletionGPT4Vision:", base64_image);
    const OPENAI_API_KEY = readAndDecryptKey()
    const openai =new OpenAI({ apiKey: OPENAI_API_KEY,dangerouslyAllowBrowser: true });  
      

    

    const System_Input = `As a Product Manager Assistant, I need you to LOGICALLY ANALYSE the image, identify all the text data in the image and output in the following JSON format.{
      "ObjectiveDefinition": "Define the goal of image.",
      "AudienceIdentification": "Identify the target user and context for the product.",
      "FunctionalRequirements": "Translate grouped information into functional requirements for the product.",
      "ContentExamination": "Observe and identify elements within the image like objects, colors, text, and symbols. Give usage context for each element based on the image. ",
      "Business Rules": "Define the business rules from the image. If its a data entry form, generate the business rules by understanding the text per line item in the image.",
      "Categorization": "Group identified elements into logical categories based on function.",
      "ContextConsideration": "Analyze the image within the context of its application.",
      "FeatureSpecification": "Specify technical capabilities needed such as object recognition and color detection.",      
      "PerformanceCriteria": "Define accuracy and speed criteria for the analysis process.",
      "UserInterfaceDesign": "Design how the analysis results will be presented to the user.",
      "ComplianceAndStandards": "Ensure the process complies with relevant laws and standards.",
      "SecurityAndPrivacy": "Address security and privacy implications, especially for personal data.",
      "PrototypeTesting": "Plan for prototype testing to validate the requirements with actual users and gather feedback."
    }
    `;
    

     // Initialize messages with a system message
     let messages = [
      { role: "system", content: System_Input },
      {
        "role": "user",
        "content": [
          {
            "type": "text",
            "text": "Output information based on the JSON format. "
          },
          {
            "type": "image_url",
            "image_url": {
              "url": base64_image
            }
          }
        ]
      }
      
    ];

    const chatCompletion = await openai.chat.completions.create({
      model: "gpt-4-vision-preview", 
      // response_format:  {"type": "json"},   
      max_tokens: 3800,  
      "messages": messages,
    });

    
    const result = chatCompletion.choices[0].message.content;
    // console.log("\nInside createChatCompletionGPT4Vision Result:", result);
    return result;
  } catch (error) {
    console.error("Error analysing image and creating chat completion:", error);
  }
}

export async function createShortenTextChatCompletionGPT4(prompt) {
  try {
    // let openai = undefined;
    const OPENAI_API_KEY = readAndDecryptKey()
    const openai =new OpenAI({ apiKey: OPENAI_API_KEY,dangerouslyAllowBrowser: true });

    const toneRules = rulesForToneAndRhetoric1() + rulesForToneAndRhetoric2();
    const wordRules = rulesForWordUsage();

    const rulesForTextOptimization = rulesForFleschKincaid() +"\n" + toneRules + "\n" + wordRules;
  
    const System_Input = "As a Content Writing Assistant, I need you to provide helpful, respectful, and honest assistance while ensuring user safety and a positive experience. Functional Requirements: Helpfulness: Always provide the most useful and accurate information. Respectfulness: Interact in a courteous and respectful manner. Honesty: If uncertain about an answer, admit it rather than providing false information. Non-Functional Requirements Safety: Avoid sharing any harmful, unethical, racist, sexist, toxic, dangerous, or illegal content. Social Unbiasedness: Ensure responses are socially unbiased. Positive Nature: Maintain a positive tone in all interactions. Sensitive Topics: Tread carefully while providing information on socially sensitive topics. Complex Queries: If a question involves multiple facets, break down the answer into understandable parts."

    const chatCompletion = await openai.chat.completions.create({
      model: "gpt-4",      
      messages: [
        { role: "system", content: System_Input },
        { role: "user", content: "Shorten the text, make it friendly:"+prompt + "\nAvoid any leading text and explanation from you. Just show the shortened text."},
        { role: "assistant", content: "Evaluate and improve the user text according to the specified rules:" + rulesForTextOptimization }
        // { role: "assistant", content: "Please evaluate and improve the user text according to the specified tone rules:" + toneRules },
        // { role: "assistant", content: "Please evaluate and improve the user text according to the specified words rules:" + wordRules },
        // { role: "assistant", content: "Please evaluate and improve the user text according to the specified readability rules:" + rulesForFleschKincaid() },
        // { role: "user", content: "Shorten the text, make it concise while following the tone rules and word rules:"+prompt }       
      ],
    });

    // result = aiResponse.data.choices[0].message['content'];
    const result = chatCompletion.choices[0].message.content;
    //console.log("\nThe AI Response:"+result)
    return result;
  } catch (error) {
    console.error("Error creating chat completion:", error);
  }
}

export async function createToneRhetoricChatCompletionGPT4(prompt) {
  try {
    // let openai = undefined;
    const OPENAI_API_KEY = readAndDecryptKey()
    const openai =new OpenAI({ apiKey: OPENAI_API_KEY,dangerouslyAllowBrowser: true });

    const toneRules1 = rulesForToneAndRhetoric1();
    const toneRules2 = rulesForToneAndRhetoric2();
    const wordRules = rulesForWordUsage();
    const readabiltyImprovementRules = rulesForFleschKincaid();
  
    const System_Input = "As a Content Writing Assistant, I need to provide helpful, respectful, and honest assistance while ensuring user safety and a positive experience. Helpfulness: Always provide the most useful and accurate information. Honesty: If uncertain about an answer, admit it rather than providing false information. Safety: Avoid sharing any harmful, unethical, racist, sexist, toxic, dangerous, or illegal content. Social Unbiasedness: Ensure responses are socially unbiased. Positive Nature: Maintain a positive tone in all interactions. Tone: Answers should be polite and positive. Safety: No harmful or biased content should be included in the answers. Sensitive Topics: Tread carefully while providing information on socially sensitive topics.";

    // Initialize messages with a system message
    let messages = [
      { role: "system", content: System_Input }
    ];
    // User's first question
    messages.push({ role: "user", content: "Please evaluate and improve the following text according to the specified tone rules:" + toneRules1 + '\n The input text is:' + prompt});

    // First call to OpenAI API
    let chatCompletion1 = await openai.chat.completions.create({
      model: "gpt-4",
      messages: messages,
    });

    // Append the assistant's reply to the messages
    messages.push({ role: "assistant", content: chatCompletion1.choices[0].message.content });
    console.log(chatCompletion1.choices[0].message.content);

    // User's follow-up question for Word  rule evaluation
    messages.push({ role: "user", content: "Please evaluate and improve the text according to the specified words rules:" + wordRules });

    // Second call to OpenAI API with the updated messages
    let chatCompletion2 = await openai.chat.completions.create({
      model: "gpt-3.5-turbo-16k",
      messages: messages,
    });
    console.log(chatCompletion2.choices[0].message.content);

    // Append the assistant's reply to the messages
    messages.push({ role: "assistant", content: chatCompletion2.choices[0].message.content });

    // User's follow-up question for improving readability
    messages.push({ role: "user", content: "Please apply the followimg readabiltiy improvement rules:" + readabiltyImprovementRules + "Check agains the tone rules:"+ toneRules2 });

    // Third call to OpenAI API with the updated messages
    let chatCompletion3 = await openai.chat.completions.create({
      model: "gpt-4",
      messages: messages,
    });
    console.log(chatCompletion3.choices[0].message.content);


    // let chatCompletion = await openai.chat.completions.create({
    //   model: "gpt-4",  
    //   temperature: 0.7,  // A higher value like 0.8 or 1.0 will make the output more random, while a lower value like 0.2 or 0.3 will make it more focused and deterministic.
    //   top_p: 0.7,        // This parameter, also known as the nucleus sampling parameter, controls the diversity of the output. A value close to 1.0 will consider a broader range of words for each token generated, making the output more diverse. A lower value like 0.7 will narrow down the choices and make the output less random.   
    //   messages: [
    //     { role: "system", content: System_Input },
    //     { role: "assistant", content: "Please evaluate and improve the following text according to the specified tone rules:" + toneRules },
    //     { role: "assistant", content: "Please evaluate and improve the text according to the specified words rules:" + wordRules },
    //     { role: "assistant", content: "Please apply the followimg readabiltiy improvement rules:" + readabiltyImprovementRules },
    //     { role: "user", content: prompt + "Does the text adhere to each of the above tone rules? If not make them adhere. Do not show evaluation rules as part of the output" }       
    //   ],
    // });

    // result = aiResponse.data.choices[0].message['content'];
    const result = chatCompletion3.choices[0].message.content;

    return result;
  } catch (error) {
    console.error("Error creating chat completion:", error);
  }
}

export async function createOptimizedTextChatCompletionGPT4(prompt) {
  try {
    // let openai = undefined;
    const OPENAI_API_KEY = readAndDecryptKey()
    const openai =new OpenAI({ apiKey: OPENAI_API_KEY,dangerouslyAllowBrowser: true });

    const toneRules = rulesForToneAndRhetoric1() + rulesForToneAndRhetoric2();
    const wordRules = rulesForWordUsage();

    const rulesForTextOptimization = rulesForFleschKincaid() +"\n" + toneRules + "\n" + wordRules;
  
    const System_Input = "As a Content Writing Assistant, I need you to provide helpful, respectful, and honest assistance while ensuring user safety and a positive experience. Functional Requirements: Helpfulness: Always provide the most useful and accurate information. Respectfulness: Interact in a courteous and respectful manner. Honesty: If uncertain about an answer, admit it rather than providing false information. Non-Functional Requirements Safety: Avoid sharing any harmful, unethical, racist, sexist, toxic, dangerous, or illegal content. Social Unbiasedness: Ensure responses are socially unbiased. Positive Nature: Maintain a positive tone in all interactions. Sensitive Topics: Tread carefully while providing information on socially sensitive topics. Complex Queries: If a question involves multiple facets, break down the answer into understandable parts."

    const chatCompletion = await openai.chat.completions.create({
      model: "gpt-4",      
      messages: [
        { role: "system", content: System_Input },
        { role: "user", content: "Optimize the text for readability while following the tone rules and word rules:"+prompt + "\nAvoid any leading text and explanation from you. Just show the optimized text."},  
        { role: "assistant", content: "Evaluate and improve the user text according to the specified rules:" + rulesForTextOptimization }

        // { role: "assistant", content: "Please evaluate and improve the user text according to the specified tone rules:" + toneRules },
        // { role: "assistant", content: "Please evaluate and improve the user text according to the specified words rules:" + wordRules },
        // { role: "assistant", content: "Please evaluate and improve the user text according to the specified readability rules:" + rulesForFleschKincaid() },
             
      ],
    });

    // result = aiResponse.data.choices[0].message['content'];
    const result = chatCompletion.choices[0].message.content;
    //console.log("\nThe AI Response:"+result)
    return result;
  } catch (error) {
    console.error("Error creating chat completion:", error);
  }
}
