import React, { useState, useEffect  } from 'react';
import './userStoryComponent.css';
import '../../common/css/loader.css';
import '../../common/css/output.css';
import '../../common/css/formattedOutput.css';
import { createChatCompletion, copyToClipboard, extractJSONFromText, renderJSON  } from './../../common/utils/utilityFunctions';

function UserStoryComponent() {
  const [userRole, setUserRole] = useState('');
  const [description, setDescription] = useState('');
  const [output, setOutput] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);

  useEffect(() => {
    let timer;
    if (isLoading) {
      timer = setInterval(() => {
        setCount(prevCount => prevCount + 1);
      }, 1000);
    } else {
      setCount(0);  // Reset the counter when loading is complete
    }
    return () => clearInterval(timer);  // Cleanup: stop the timer
  }, [isLoading]);

  const generatePrompt = (userRole, description) => {
    return `Generate a comprehensive review of the User Story for Actor: ${userRole}, Feature: ${description}. Think critically. Ensure to comment on each of the Acceptance Criteria. Specifically interested in tracking features, edge cases, technical specifications for engineers. Be as detailed as possible as Your insights will be valuable in refining the implementation plan. Use the following JSON format for response: {
      "UserStory": "As a PLACEHOLDER, I want to be able to PLACEHOLDER, So That I can PLACEHOLDER",
      "Precondition": "",
      "AcceptanceCriteria": [{
        "TITLE PLACEHOLDER": [
          "PLACEHOLDER ",
          "PLACEHOLDER "
        ]
      }],
      "EdgeCases": [{
        "TITLE PLACEHOLDER": [
          "PLACEHOLDER ",
          "PLACEHOLDER "
        ]
      }]
    }`;
  };

  


const handleSubmit = async () => {
    setIsLoading(true);  // Set loading state to true when API call starts
    
    try {
      
      const prompt = generatePrompt(userRole, description);
      // console.log("User Story Prompt:\n"+ prompt)
      //const message = await callOpenAI(prompt);
    //   const response = await axios.post('http://localhost:5000/call_OpenAI', {
    //   prompt: prompt,
    // });
    const response = await createChatCompletion(prompt);

    const extractedJSON = extractJSONFromText(response);//.data.message); // Assume this function is imported from utilityFunctions.js
    setOutput(extractedJSON);      

    //setOutput(message);
    } catch (error) {
      console.error("There was an error:", error);
      setError("There was an error!\n\n"+ error);// Optional: set some state to indicate the error in the UI
      
    }
    
    setIsLoading(false); // Reset loading state to false when API call is done
  };



  return (
    <div className="user-story-container">
      <h1 className="main-heading">User Story Generator</h1>
      <h3 className="sub-heading">Describe your feature in detail to generate better results.</h3>
      <input 
        className="user-story-select"
        type="text"
        placeholder="Enter the Name of the Person or System involved in the User Story"
        onChange={(e) => setUserRole(e.target.value)}
      />
      <div classname="labeldiv">
        <label htmlFor="featuresDescription" className="label">Feature description:</label>
      </div>
      
      <textarea
        className="user-story-textarea"
        rows="5"
        onChange={(e) => setDescription(e.target.value)}
      />
      {!isLoading ? <button className="user-story-button" onClick={handleSubmit}>Generate User Story</button> : null}
     
      <div className="user-story-output" id="output-section">
       
        {isLoading ? (
            <div className="loader">{count}s Analyzing...</div>
        ) : (
            <div className="user-story-output output-container">
                <strong className="output-title">Output:</strong>
                {/*formatOutput(output)*/}
                
                {output && typeof output === 'object' ? 
                            renderJSON(output) : // Render JSON using your utility function
                            error
                        }
                <i className="fas fa-copy copy-icon" onClick={() => copyToClipboard('output-section')}></i>
            </div>
        )}
      </div>
    </div>
  );
}

export default UserStoryComponent;
