import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ideaSorter.css';
import '../../common/css/loader.css';
import { extractJSONFromText, createChatCompletionGPT4, RenderTable, RenderTable2 } from '../../common/utils/utilityFunctions';

const IdeaSorter = () => {
  const [ideas, setIdeas] = useState('');
  const [sortedIdeas, setSortedIdeas] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [count, setCount] = useState(0);

  useEffect(() => {
    let timer;
    if (isLoading) {
      timer = setInterval(() => {
        setCount(prevCount => prevCount + 1);
      }, 1000);
    } else {
      setCount(0);  // Reset the counter when loading is complete
    }
    return () => clearInterval(timer);  // Cleanup: stop the timer
  }, [isLoading]);


  const handleTextAreaChange = (e) => {
    setIdeas(e.target.value);
  };


  const generatePrompt = (description) => {
    // const sortedIdeasDataJSON = {
    //   "sortedIdeas": [{
    //     "category": "Feasibility",
    //     "ideas": [{
    //       "name": "Idea1",
    //       "description": "Description for Idea1",
    //       "guidingQuestions": {
    //         "purpose": "Purpose of Idea1",
    //         "industry": "Industry related to Idea1",
    //         "problemToSolve": "Problem that Idea1 aims to solve",
    //         "keyFeatures": "Key features or components of Idea1",
    //         "targetAudience": "Target audience or customer for Idea1",
    //         "implementation": "How Idea1 would be implemented",
    //         "requiredResources": "Resources or skills required for Idea1",
    //         "risks": "Potential risks or challenges for Idea1",
    //         "benefits": "Potential benefits or advantages of Idea1",
    //         "alignment": "How Idea1 aligns with the company's mission"
    //       }
    //     }]
    //   }]
    // };

    
    // return `Given this list of ideas and context: ${description}. Use the following guidelines to categorize the ideas as appropriate: 
    // Group ideas based on their level of feasibility or practicality.
    // Categorize ideas based on their potential impact or benefit to the organization.
    // Sort ideas based on their level of innovation or creativity.
    // Group ideas based on their alignment with the organization's mission or values.
    // Categorize ideas based on their level of risk or potential challenges.
    // Sort ideas based on their level of urgency or importance.
    // Group ideas based on their potential cost or resource requirements.
    // Categorize ideas based on their level of customer or stakeholder interest.
    // Sort ideas based on their level of complexity or difficulty to implement.
    // Group ideas based on their potential for collaboration or partnership with other organizations.
    // In Addition ask yourself these guiding questions while categorizing:
    // What is the main purpose or goal of the idea?
    // What industry or market does the idea relate to?
    // What problem or challenge does the idea aim to solve?
    // What are the key features or components of the idea?
    // Who is the target audience or customer for the idea?
    // How would the idea be implemented or executed?
    // What resources or skills would be required to bring the idea to life?
    // What potential risks or challenges could arise in implementing the idea?
    // What are the potential benefits or advantages of the idea?
    // How does the idea align with the company's mission, vision, and values?
    // Iterate through each Idea. Provide Oputput in JSON Format by using the following template: 
    //  ` + JSON.stringify(sortedIdeasDataJSON);;

    const sortedIdeasDataJSON = {
      "sortedIdeas": [{
        "Category": "PLACEHOLDER",
        "Ideas": "Idea1",
        "Feasibility": "Description for feaibility",
        "Complexity": "Possible Values (High, Medium, Low)"
        
      }]
    };

    return `Given this list of ideas and context: ${description}. 
    Iterate through ALL Ideas and categorize always. Give feasibility reason per idea. Provide implementation complexity. Group by Category. Your JSON Response Template: 
     ` + JSON.stringify(sortedIdeasDataJSON);
    
  };

  const sortIdeas = async () => {
    setIsLoading(true);
    let extractedJSON;
    try {
      let prompt = generatePrompt(ideas);      
      // console.log("\nThe PROMPT:"+prompt)
      // let response = await axios.post('http://localhost:5000/call_OpenAI', {
      //   prompt: prompt,
      // })
      
      let response = await createChatCompletionGPT4(prompt);;
      
      // Check if response contains the expected data
      // if (response.data && response.data.message) {
        if (response) {
         extractedJSON = extractJSONFromText(response);   
        // console.log("\n\nExtracted JSON:\n"+extractedJSON)  
        // console.log("\n\nType OF JSON :"+typeof extractedJSON);   
        //Below is debug code to check the output.
        // if (typeof extractedJSON === "string") {
        //   try {
        //     console.log("\n\nExtracted JSON String :\n"+extractedJSON);      
        //   } catch (e) {
        //     console.error("Error showing  extractedJSON string:", e);
            
        //   }
        // } else if (typeof extractedJSON === "object") {
        //   console.log("\n\nExtracted JSON Object:\n"+JSON.stringify(extractedJSON)); 
        // } else {
        //   console.log("\n\nNO Extracted JSON Array:\n"+extractedJSON); 
        // }

        setSortedIdeas(extractedJSON);     

        // //Below code is to verify the output to ensure its in the right format.
        // const STANDARD_TEMPLATE = {
        //   "sortedIdeas": [{
        //     "Category": "PLACEHOLDER",
        //     "Ideas": "Idea1",
        //     "Feasibility": "Description for feaibility",
        //     "Complexity": "Possible Values (High, Medium, Low)"
            
        //   }]
        // };
        // prompt = 'Compare these two JSON. STANDARD_TEMPLATE:\n'
        // prompt += JSON.stringify(STANDARD_TEMPLATE)
        // prompt += "\n Input JSON:\n" + JSON.stringify(extractedJSON)
        // prompt += "\nINSTRUCTION: 1. Return JSON output in STANDARD_TEMPLATE format. 2. Include every single Idea in the transformed JSON. Do not miss any idea."
        // console.log("\n2. The PROMPT:"+prompt)

        // // response = await axios.post('http://localhost:5000/call_OpenAI', {
        // //   prompt: prompt,
        // // });

        // response = await createChatCompletion(prompt);
        
        // extractedJSON = extractJSONFromText(response);   
        // console.log("\n\n2Extracted JSON:\n"+extractedJSON)     
        // //Below is debug code to check the output.
        // if (typeof extractedJSON === "string") {
        //   try {
        //     console.log("\n\n2Extracted JSON String :\n"+extractedJSON);      
        //   } catch (e) {
        //     console.error("Error showing  extractedJSON string:", e);
            
        //   }
        // } else if (typeof extractedJSON === "object") {
        //   console.log("\n\n2Extracted JSON Object:\n"+JSON.stringify(extractedJSON)); 
        // } else {
        //   console.log("\n\nNO Extracted JSON Array:\n"+extractedJSON); 
          
        // }

        // setSortedIdeas(extractedJSON);     

      } else {
        setError('Unexpected AI server response.');
      }

    } catch (error) {
      console.error("An error occurred while fetching data:", error);
      console.error("Server response:", error.response);
      setError(`An error occurred while sorting ideas: ${error.response ? error.response.data : error.message}`);
    }

    setIsLoading(false);
  };

  return (
    <div className="ideaSorter-container">
      <h1 className="main-heading">Ideas Categorizer </h1>
      <h3 className="sub-heading">Enter all your ideas below to have them categorized and analyzed</h3>
      <div classname="labeldiv">
        <label htmlFor="featuresDescription" className="label">Ideas:</label>
      </div>
      
      <textarea
        id="ideas"
        rows="10"
        className="ideaSorter-textArea"
        value={ideas}
        onChange={handleTextAreaChange}
      ></textarea>
      {!isLoading ? <button onClick={sortIdeas} className="ideaSorter-sortButton">Sort Ideas</button> : null}
     
      {isLoading ? (
        <div className="loader">{count}s Analyzing...</div>
      ) : (
        <div className="ideaSorter-sortedIdeas">
            <h3>Sorted Ideas:</h3>
            <h4 style={{ fontWeight: 'bold' }}>Notice: All AI-generated content must undergo human review prior to publication.</h4>
          {
            // sortedIdeas ?
            //   (typeof sortedIdeas === 'object' ?
            //     <RenderTable sortedIdeas={sortedIdeas} /> : 
            //        <RenderTable2 sortedIdeas={sortedIdeas} />
            //     <pre className="ideaSorter-error">Error in Rendering Sorted Ideas JSON Object</pre>
            //   ) :
            //   (error ? <div className="ideaSorter-error">Error: {error}</div> : null)
            typeof sortedIdeas === 'object' ? (
              <RenderTable sortedIdeas={sortedIdeas} />
            ) : typeof sortedIdeas === 'undefined' ? (
              <RenderTable2 sortedIdeas={sortedIdeas} />
            ) : (
              <div className="ideaSorter-error">{error}</div>
            )
          }
        </div>
      )}
    </div>
  );
};


export default IdeaSorter;
