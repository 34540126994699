import React, { useState,useEffect } from 'react';
import axios from 'axios';
import { createChatCompletion } from '../../common/utils/utilityFunctions';
import './pressReleaseGenerator.css'; // Rename your CSS files accordingly
import '../../common/css/loader.css';

const PressReleaseGenerator = () => {
  const [pressReleaseInput, setPressReleaseInput] = useState('');
  const [generatedPressRelease, setGeneratedPressRelease] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [count, setCount] = useState(0);

  useEffect(() => {
    let timer;
    if (isLoading) {
      timer = setInterval(() => {
        setCount(prevCount => prevCount + 1);
      }, 1000);
    } else {
      setCount(0);  // Reset the counter when loading is complete
    }
    return () => clearInterval(timer);  // Cleanup: stop the timer
  }, [isLoading]);

  const handleTextAreaChange = (e) => {
    setPressReleaseInput(e.target.value);
  };

  const generatePrompt = (inputText) => {
    return `You are now a seasoned public relations professional. Generate a professional and concise press release based on the following information: ${inputText}. Ignore any idustry related jargon.`;
  };

  //Help Icon
  const [showHelpModal, setShowHelpModal] = useState(false); // State to manage help modal visibility
  const toggleHelpModal = () => { // Function to toggle help modal
    setShowHelpModal(!showHelpModal);
  };


  const generatePressRelease = async () => {
    setIsLoading(true);

    const prompt = generatePrompt(pressReleaseInput);

    try {
      // const response = await axios.post('http://localhost:5000/call_OpenAI', {
      //   prompt: prompt,
      // });

      const response = await createChatCompletion(prompt);

      setGeneratedPressRelease(response);
    } catch (error) {
      console.error("An error occurred while fetching data:", error);
      console.error("Server response:", error.response);
      setGeneratedPressRelease("An error occurred while generating the press release.");
    }

    setIsLoading(false);
  };

  return (
    <div className="container">
      <div className="header">
        <div className="header-text">
          <h1>Press Release</h1>
          <h4 className="sub-header">Briefly describe your announcement to generate a press release.</h4>
        </div>
        <button className="info-button" onClick={toggleHelpModal}>i</button>
      </div>

      {/* Help Modal */}
      {showHelpModal && (
        <div className="help-modal">
          <button className="close-button" onClick={toggleHelpModal}>×</button>
          <div className="help-content">
          <h4>The 5 W's of a Press Release</h4>
            <p>These are the basic elements that should be included in a press release:</p>
            <ul>
              <li><strong>Who:</strong> This refers to the people or organizations involved in the event or situation.</li>
              <li><strong>What:</strong> This refers to the specific event or situation being reported on.</li>
              <li><strong>Where:</strong> This refers to the location of the event or situation.</li>
              <li><strong>When:</strong> This refers to the time and date of the event or situation.</li>
              <li><strong>Why:</strong> This refers to the reason or purpose for the event or situation.</li>
            </ul>
            <p>Including these elements helps journalists and readers quickly understand the key details.</p>
          </div>
        </div>
      )}
       
      <textarea
        id="pressReleaseInput"
        rows="10"
        className="text-area"
        value={pressReleaseInput}
        onChange={handleTextAreaChange}
      ></textarea>
      {!isLoading ? <button onClick={generatePressRelease} className="generate-button">Generate Press Release</button> : null}
      {isLoading ? (
        <div className="loader">{count}s Analyzing...</div>
      ) : (
        <div className="generated-press-release">
          <h3>Generated Press Release:</h3>
            <h4 style={{ fontWeight: 'bold' }}>Notice: All AI-generated content must undergo human review prior to publication.</h4>
          {generatedPressRelease ?
            (typeof generatedPressRelease === 'string' ?
                <pre>{generatedPressRelease}</pre> :
                <pre>Error in Rendering Generated Press Release JSON Object</pre>
            ) :
            (error ? <div>Error: {error}</div> : null)
          }
        </div>
      )}
    </div>
  );
};

export default PressReleaseGenerator;
