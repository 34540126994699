import React, { useState } from 'react';
import axios from 'axios';
import './releaseNoteGenerator.css';
import '../../common/css/loader.css';
import '../../common/css/renderJSON.css';
import {  createChatCompletion  } from '../../common/utils/utilityFunctions';

const ReleaseNoteGenerator = () => {
  const [featuresDescription, setFeaturesDescription] = useState('');
  const [apiOutput, setApiOutput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleTextAreaChange = (e) => {
    setFeaturesDescription(e.target.value);
  };

  const generatePrompt = (description) => {
    // return `Improve this Release notes.  I need to create a detailed release note for a new version of Feature: Feature: ${description}. and clearly explain the changes to customers. Think critically and Be as detailed as possible. Make it concise, more professional. Return as JSON document.`;
    return `Improve this Release notes.  I need to create a detailed release note for a new version of Feature: Feature: ${description}. and clearly explain the changes to customers. Think critically and Be as detailed as possible. Make it concise, more professional.`;
  };

  const generateReleaseNotes = async () => {    

    setIsLoading(true);
    const generatedNotes = `Release Notes:\n${featuresDescription}`;    

    const prompt = generatePrompt(featuresDescription);
    try {
        // const response = await axios.post('http://localhost:5000/call_OpenAI', {
        // prompt: prompt,
        // });  
        const response = await createChatCompletion(prompt);      
         
        // setApiOutput(JSON.parse(response.data.message)); //Convert the output in to JSON Object so that i can be rendered.
        setApiOutput(response);
        
    } catch (error) {
        console.error("An error occurred while fetching data from OpenAI:", error);
        console.error("Server response:", error.response); 
        setApiOutput("An error occurred while generating Release Notes.");
      }
    
    setIsLoading(false); // Reset loading state to false when API call is done
  };

  return (
    <div className="container">
      <h1 className="main-heading">Release Notes Generator</h1>
      <h3 className="sub-heading">Describe your feature(s) in detail to generate better results.</h3>
      
      <div classname="labeldiv">
        <label htmlFor="featuresDescription" className="label">Feature(s) description:</label>
      </div>
      <textarea
        id="featuresDescription"
        rows="10"
        className="text-area"
        value={featuresDescription}
        onChange={handleTextAreaChange}
      ></textarea>
      {!isLoading ? <button onClick={generateReleaseNotes} className="generate-button">Generate Release Notes</button> : null}

     
            {isLoading ? (
                    <div className="loader">Analyzing...</div>
                ) : (
                    <div className="release-notes">   
                        <h3>Generated Release Notes:</h3>
            <h4 style={{ fontWeight: 'bold' }}>Notice: All AI-generated content must undergo human review prior to publication.</h4>
                {
                /*apiOutput ?
                    (typeof apiOutput === 'object' ? 
                    renderJSON(apiOutput) :cd
                    <pre>Error in Rendering RELEASE NOTES JSON Object</pre>
                    )
                    : 
                    (error ? <div>Error: {error}</div> : null)*/
                    apiOutput ?                    
                    <pre>{apiOutput}</pre>: (error ? <div>Error: {error}</div> : null)
                } 
                 
            </div>
        )}

      
    </div>
  );
};

export default ReleaseNoteGenerator;
