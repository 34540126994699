import React, { useState, useEffect } from 'react';
import './testCasesComponent.css';
//import { callOpenAI } from './openaiService';  // adjust the path as necessary
import '../../common/css/loader.css';
import '../../common/css/output.css';
import '../../common/css/formattedOutput.css';
import { copyToClipboard, extractJSONFromText, renderJSON, createChatCompletion } from '../../common/utils/utilityFunctions';


const TestCasesComponent = () => {
  const [selectedRole, setSelectedRole] = useState('');
  const [description, setDescription] = useState('');
  const [selectedFunctionalCategory, setSelectedFunctionalCategory] = useState('');
  const [selectedNonFunctionalCategory, setSelectedNonFunctionalCategory] = useState('');
  const [generatedPrompt, setGeneratedPrompt] = useState('');
  const [apiResponse, setApiResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [count, setCount] = useState(0);

  useEffect(() => {
    let timer;
    if (isLoading) {
      timer = setInterval(() => {
        setCount(prevCount => prevCount + 1);
      }, 1000);
    } else {
      setCount(0);  // Reset the counter when loading is complete
    }
    return () => clearInterval(timer);  // Cleanup: stop the timer
  }, [isLoading]);


  const functionalCategories = ['Feature Testing', 'Performance Testing', 'Usability Testing', 'Accessibility Testing'];
  // const nonFunctionalCategories = ['Performance Testing', 'Usability Testing', 'Accessibility Testing'];

  // const handleCategoryChange = (e) => {
  //   const value = e.target.value;
  //   setTestCategories(prevCategories => {
  //     const exists = prevCategories.some(item => item === value);
  //     if (exists) {
  //       return prevCategories.filter(item => item !== value);
  //     } else {
  //       return [...prevCategories, value];
  //     }
  //   });
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);



    // const prompt = `Actor: ${selectedRole}\nDescription: ${description}\nFunctional Test Category: ${selectedFunctionalCategory}\nNon-Functional Test Category: ${selectedNonFunctionalCategory}`;
    let prompt = `Generate Test Cases for Actor: ${selectedRole}\nDescription: ${description}`;

    if (selectedFunctionalCategory) {
      prompt += `\Test Category: ${selectedFunctionalCategory}`;
    }

    // if (selectedNonFunctionalCategory) {
    //   prompt += `\nNon-Functional Test Category: ${selectedNonFunctionalCategory}`;
    // }
    prompt += '. \nThink critically and include all edge cases. Consider valid, invalid scenarios. Ensure that the tasks cover various functionalities and critical user journeys.  Be as detailed as possible AND generate multiple test cases. Return Test Cases in JSON format.'
    prompt += '\nJSON Format to be used is:\n'
    prompt += `
{
    "Test Case Type": [
        {
          "TestCaseID": "",
          "Description": "",
          "Precondition": "",
          "StepsToReproduce": [
              "1. ",
              "2. ",
              "3. "
          ],
          "ExpectedResult": ""
        }
        
    ]
}
`;
    // console.log("Test Case Prompt:\n", prompt);

    setGeneratedPrompt(prompt);

    try {
      // const response = await axios.post('http://localhost:5000/call_OpenAI', {
      //   prompt: prompt,
      // });
      // //setApiResponse(response.data.message);
      // // Use extractJSONFromText function to extract JSON
      // const extractedJSON = extractJSONFromText(response.data.message); // Assume this function is imported from utilityFunctions.js

      const response = await createChatCompletion(prompt);
      const extractedJSON = extractJSONFromText(response);

      // console.log("\n Inside TEST CASE the EXTRACTED JSON is \n:" + extractedJSON)
      setApiResponse(extractedJSON);
    } catch (error) {
      console.error("There was an error:", error);
      setApiResponse("There was an error!\n\n" + error);
    }

    setIsLoading(false);
  };


  return (
    <div className="test-cases-container">
      <h1 className="main-heading">Test Case Generator</h1>
      <h3 className="sub-heading">Describe your feature in detail to generate better results.</h3>
      <form onSubmit={handleSubmit} className="form-container">
        <div className="form-group">
          <label className="form-label">Enter Actor:</label>
          <input
            type="text"
            className="form-input"
            value={selectedRole}
            onChange={(e) => setSelectedRole(e.target.value)}
            placeholder="Enter the Name of the Person or System involved in the User Story"
          />
        </div>

        <div className="form-group">
          <label className="form-label">Feature Description:</label>
          <textarea className="form-textarea" rows="5" value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
        </div>

        <div className="form-group">
          <label className="form-label">Test Categories:</label>
          <select className="form-select" value={selectedFunctionalCategory} onChange={(e) => setSelectedFunctionalCategory(e.target.value)}>
            <option value="">Select Category</option>
            {functionalCategories.map((category, index) => (
              <option value={category} key={index}>{category}</option>
            ))}
          </select>
        </div>

        {!isLoading ?
          <button type="submit" className="form-button">Submit</button> : null
        }
        
      </form>

      {/* <button type="submit" className="form-button" onClick={handleSubmit}>Submit</button> */}

      <div className="test-cases-output" id="output-section">
        {isLoading ? (
          <div className="loader">{count}s Analyzing...</div>
        ) : (
          <div className="test-cases-output output-container">
              <h3>Generated Test Cases:</h3>
              <h4 style={{ fontWeight: 'bold' }}>Notice: All AI-generated content must undergo human review prior to publication.</h4>
            <i className="fas fa-copy copy-icon" onClick={copyToClipboard}></i>

            {apiResponse && typeof apiResponse === 'object' ?
              renderJSON(apiResponse) : // Render JSON using your utility function
              error
            }
          </div>
        )}
      </div>
    </div>
  );
};

export default TestCasesComponent;
