import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
// import { generatePlantUMLImage3 } from './plantUML2'
import { createChatCompletion } from '../../common/utils/utilityFunctions';
import './plantUML.css'
import '../../common/css/loader.css';
import { encode } from 'plantuml-encoder';

const generatePlantUMLImage3 = (umlCode) => {
  const encodedUML = encode(umlCode);
  const imageUrl = `http://www.plantuml.com/plantuml/img/${encodedUML}`;
  //console.log("\nImageURL:\n" + imageUrl)

  return (
    <div>
      <img src={imageUrl} alt="PlantUML Diagram" />
    </div>
  );
};

// PlantUMLDiagram function that you provided
const PlantUMLDiagram = ({ diagramCode }) => {
  
  return generatePlantUMLImage3(diagramCode)
};

// Main Componentzip_deflate
const PlantUMLGenerator = () => {
const [userInput, setUserInput] = useState('');
const [diagramCode, setDiagramCode] = useState(null);
const [apiOutput, setApiOutput] = useState('');
const [isLoading, setIsLoading] = useState(false);
const [count, setCount] = useState(0);

  useEffect(() => {
    let timer;
    if (isLoading) {
      timer = setInterval(() => {
        setCount(prevCount => prevCount + 1);
      }, 1000);
    } else {
      setCount(0);  // Reset the counter when loading is complete
    }
    return () => clearInterval(timer);  // Cleanup: stop the timer
  }, [isLoading]);

  const cleanAPIResponse = (input) => {
    //console.log("Input received: \n" + input);  // Debugging line

    const startUmlIndex = input.indexOf('@startuml');
    const endUmlIndex = input.indexOf('@enduml');

    // console.log("\n\n @STARTUML POS:"+startUmlIndex )
    // console.log("\n @ENDUML POS:"+endUmlIndex )

    
    if (startUmlIndex !== -1 && endUmlIndex !== -1) {
      //const modifiedInput = input.substring(startUmlIndex + '@startuml'.length, endUmlIndex).trim();
      const modifiedInput = input.substring(startUmlIndex, endUmlIndex+'@enduml'.length).trim();
      // console.log("\nCleaned API Response:\n" + modifiedInput);
      return modifiedInput;
    } else {
      // console.log('Unable to generate the diagram. You can either improve the description or retry clicking again.');
      return 'Unable to generate the diagram. You can either improve the description or retry clicking again.';

      
    }
  };

  const generatePrompt = (description) => {
    return `You want to create a Flow Chart using rectangles, decision boxes etc.  Create PlantUML script for the following: ${description}. Ensure no errors in PlantUML script and output returned MUST HAVE StartUML, EndUML of the plantUML script. Give it a title in the UML.`;
    setIsLoading(true);
  };

  const handleSubmit = async() => {
    // const strippedInput = stripTags(userInput);
    // console.log("User Input\n" +userInput )
    setIsLoading(true);
    let prompt =  generatePrompt(userInput)
    // console.log("\ isLoading:\n" +isLoading)
    // console.log("\ API prompt:\n" + prompt);
    let cleanedUML;
    try {
      // const response = await axios.post('http://localhost:5000/call_OpenAI', {
      //   prompt: prompt,
      // });
      const response = await createChatCompletion(prompt);
 
      // console.log("\ API Response:\n" + response);
      const result = response; //.data.message
      // console.log("\ API Variable value:\n" + result);
      setApiOutput(result);
      
      cleanedUML = cleanAPIResponse(result);

    } catch (error) {
      console.error("An error occurred while fetching data:", error);
      console.error("Server response:", error.response);
      setApiOutput("An error occurred while optimizing text.");
    }
   
    setDiagramCode(cleanedUML);
    setIsLoading(false);
  };

 
  return (
    <div className="main-container">
      <h1 className="main-heading">Diagram Generator</h1>
      <h3 className="sub-heading">Describe your feature in detail to generate better results.</h3>
      <textarea
        className="input-textarea"
        rows="10"
        cols="50"
        value={userInput}
        onChange={(e) => setUserInput(e.target.value)}
      ></textarea>
      <br />
      {!isLoading ? <button className="submit-button" onClick={handleSubmit}>Generate Diagram</button> : null}
      <br />
      {isLoading ? (
        <div className="loader">{count}s Analyzing...</div>
      ) : (
        <div className="optimized-text">
          <h3>Generated Diagram:</h3>
            <h4 style={{ fontWeight: 'bold' }}>Notice: All AI-generated content must undergo human review prior to publication.</h4>
          {diagramCode && <PlantUMLDiagram diagramCode={diagramCode} />}
        </div>
      )}
      
    </div>
  );
};

export default PlantUMLGenerator;
