import React, { useState, useEffect } from 'react';
// import axios from 'axios';
import './textOptimizer.css'; // Assuming you rename the CSS files too
import '../../common/css/loader.css';
import { createChatCompletion, createOptimizedTextChatCompletionGPT4, createShortenTextChatCompletionGPT4, createToneRhetoricChatCompletionGPT4 } from '../../common/utils/utilityFunctions';

const TextOptimizer = () => {
  const [textDescription, setTextDescription] = useState('');
  const [apiOutput, setApiOutput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [count, setCount] = useState(0);
  const [loaderText, setLoaderText] = useState('');
  const [outputTitle, setOutputTitle] = useState('');

  

  useEffect(() => {
    let timer;
    if (isLoading) {
      timer = setInterval(() => {
        setCount(prevCount => prevCount + 1);
      }, 1000);
    } else {
      setCount(0);  // Reset the counter when loading is complete
    }
    return () => clearInterval(timer);  // Cleanup: stop the timer
  }, [isLoading]);

  const handleTextAreaChange = (e) => {
    setTextDescription(e.target.value);
  };

  const generatePrompt = (description) => {
    return `Revise my notes to enhance professionalism and conciseness by focusing on key points, using clear and concise language, and organizing information in a logical manner. Notes: ${description}.`;
  };

  // Function to handle text shortening
  const shortenText = async () => {
    setIsLoading(true);
    setOutputTitle('Shortened Text:');

    let prompt = `You are a content writers assistant. Shorten the following text:\n${textDescription} `
   
    
    try{
      setLoaderText('Shortening Text...');
      let response = await createShortenTextChatCompletionGPT4(prompt);

      setApiOutput(response);
    }catch{
      console.error("An error occurred while shortening text:", error);
      console.error("Server response:", error.response);
      setApiOutput("An error occurred while shortening text. Try again.\n Error:"+ error.response);
    }
    
    
    setIsLoading(false);
  };

  // Function to handle text shortening
  const adjustTone = async () => {
    setIsLoading(true);
    setOutputTitle('Adjusted Text Tone:');

    let prompt = `You are a content writers assistant. Adjust tone of the following text:\n${textDescription} `;
   
    
    try{
      setLoaderText('Adjusting text tone...');
      const response = await createToneRhetoricChatCompletionGPT4(prompt);
      setApiOutput(response);
    }catch{
      console.error("An error occurred while shortening text:", error);
      console.error("Server response:", error.response);
      setApiOutput("An error occurred while shortening text. Try again.\n Error:"+ error.response);
    }
    
    
    setIsLoading(false);
  };

  const optimizeText = async () => {
    setIsLoading(true);
    setOutputTitle('Optimized Text:');
    const originalText = `Text to be Optimized:\n${textDescription}`;
    const prompt = generatePrompt(textDescription);

    try {
      // const response = await axios.post('http://localhost:5000/call_OpenAI', {
      //   prompt: prompt,
      // });

      const response = await createOptimizedTextChatCompletionGPT4(prompt);

      // setApiOutput(response.data.message);
      setApiOutput(response);
    } catch (error) {
      console.error("An error occurred while fetching data:", error);
      console.error("Server response:", error.response);
      setApiOutput("An error occurred while optimizing text.");
    }

    setIsLoading(false);
  };

  return (
    <div className="container">
      <label htmlFor="textDescription" className="label">Text to be Improved:</label>
      <textarea
        id="textDescription"
        rows="10"
        className="text-area"
        value={textDescription}
        onChange={handleTextAreaChange}
      ></textarea>
      {!isLoading ?
        <button onClick={optimizeText} className="textOptimizer-button button-margin">
          <i className="material-icons">build</i> Optimize Text
        </button>
      : null}
      {/* New Shorten Text button */}
      {!isLoading ?
        <button onClick={shortenText} className="textOptimizer-button button-margin">
          <i className="material-icons">short_text</i> Shorten Text
        </button>
      : null}
      {/* New Adjust Tone and Rhetoric button */}
      {!isLoading ?
        <button onClick={adjustTone} className="textOptimizer-button button-margin">
          <i className="material-icons">edit_note</i> Adjust Tone
        </button>
      : null}

      {isLoading ? (
        <div className="loader">{count}s {loaderText}</div>
      ) : (
        <div className="optimized-text">
          <h3>{outputTitle}</h3>
          <h4 style={{ fontWeight: 'bold'}}>Notice: All AI-generated content must undergo human review prior to publication.</h4>
          {apiOutput ?
            (typeof apiOutput === 'string' ?
                <pre>{apiOutput}</pre> :
                <pre>Error in Rendering Optimized Text JSON Object</pre>
            ) :
            (error ? <div>Error: {error}</div> : null)
          }
        </div>
      )}
    </div>
  );
};

export default TextOptimizer;
