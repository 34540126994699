

import { LogLevel } from "@azure/msal-browser";


export const msalConfig = {
    auth: {
        clientId: '3d59a627-8427-4bd2-bb1c-c686c419093a', // 'YOUR_APP_ID', // This is the ONLY mandatory field that you need to supply.
        authority: 'https://login.microsoftonline.com/aa6efdaa-26e2-4eaa-a2da-91654b9faeda', // 'https://login.microsoftonline.com/{YOUR_APP_TENANT_ID}', // Defaults to "https://login.microsoftonline.com/common"
        redirectUri: '/', // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
        postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
    },
};


export const loginRequest = {
    scopes: []
};

export const silentRequest = {
    scopes: ["openid", "profile"],
    loginHint: "example@domain.net"
};
