import React, { useState, useEffect  } from 'react';
import './initiativeComponent.css';
import '../../common/css/loader.css';
import '../../common/css/output.css';
import '../../common/css/formattedOutput.css';
import { createChatCompletion, copyToClipboard, extractJSONFromText, renderJSON, createChatCompletionGPT4  } from '../../common/utils/utilityFunctions';

function InitiativeComponent() {
  const [userRole, setUserRole] = useState('');
  const [description, setDescription] = useState('');
  const [output, setOutput] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [loaderText, setLoaderText] = useState('');

  useEffect(() => {
    let timer;
    if (isLoading) {
      timer = setInterval(() => {
        setCount(prevCount => prevCount + 1);
      }, 1000);
    } else {
      setCount(0);  // Reset the counter when loading is complete
    }
    return () => clearInterval(timer);  // Cleanup: stop the timer
  }, [isLoading]);

  const generatePrompt = (userRole, description) => {
    return `Generate a comprehensive product initative documentation: The is the Feature: ${description}. Think critically. Ensure to comment on each of the following: 1.Summary, 2.Problem Statement, 3.Business Values, 4.Customer Value Proposition, 5. User Personas and impact, 6.Sample Workflow, 7.Considerations for Engineering, 8. Considerations for Engineering, 9.High-Level Acceptance Criteria, 10.Engineering Specifications. Be as detailed as possible. Use the following JSON format for response:{
        "Title": "Comprehensive Review of the Centralized Email Digest Service on MyPowerSchool Portal",
        "Sections": [
          {
            "Summary": "Sample Summary"
          },
          {
            "Problem Statement": "Samlple Problem Statement"
          },
          {
            "Business Values":  [
              "Sample Business Values",
              "XXXX",
              "XXXX",
              "XXXX"
            ]
          },
          {
            "Customer Value Proposition":"[Sample customer value proposition"
            ]
          },
          {
            "User Impact":[
              "User XXX: Impact XXXX",
              "User XXX: Impact XXXX",
              "User XXX: Impact XXXX",
              "...",
            ]
          },
          {
            "Sample Workflow": [
              "Smaple workflow",
              "User sets preferences for what notifications to include and the frequency of the email digests."              
            ]
          },
          {
            "Considerations for Engineering": [
              "Scalability",
              "Data Integrity",
              "Security"
            ]
          },
          
          {
            "High-Level Acceptance Criteria": [
              "High-Level Acceptance Criteria",
              "High-Level Acceptance Criteria",
              "High-Level Acceptance Criteria",
              "...",
            ]
          },
          {
            "Engineering Specifications": [
              "Engineering Specifications",
              "Engineering Specifications",
              "Engineering Specifications",
              "...",
            ]
          }
        ]
      }
    `;
  };

  


// const handleSubmit = async () => {
//     setIsLoading(true);  // Set loading state to true when API call starts
    
//     try {
      
//       const prompt = generatePrompt(userRole, description);
  
//     const response = await createChatCompletionGPT4(prompt);

//     const extractedJSON = extractJSONFromText(response); // Assume this function is imported from utilityFunctions.js
//     setOutput(extractedJSON);      

//     //setOutput(message);
//     } catch (error) {
//       console.error("There was an error:", error);
//       setError("There was an error!\n\n"+ error);// Optional: set some state to indicate the error in the UI
      
//     }
    
//     setIsLoading(false); // Reset loading state to false when API call is done
//   };

const handleSubmit = async () => {
  setIsLoading(true);  // Set loading state to true when API call starts
  setLoaderText('Analysing...');
  
  let maxRetries = 3; // Set the maximum number of retries
  let delay = 1000; // Delay between retries in milliseconds

  for (let i = 0; i < maxRetries; i++) {
    try {
      const prompt = generatePrompt(userRole, description);
      const response = await createChatCompletionGPT4(prompt);
      setLoaderText('Formatting Output...');
      const extractedJSON = extractJSONFromText(response); // Assume this function is imported from utilityFunctions.js
      setOutput(extractedJSON);
      break; // Exit the loop if successful
    } catch (error) {
        console.error(`Attempt ${i + 1} failed: ${error.message}`);
        setError("There was an error!\n\n" + error); // Optional: set some state to indicate the error in the UI
        setLoaderText('Analysing...');
        
        // If the maximum number of retries is reached, throw an error
        if (i === maxRetries - 1) {
          throw new Error('Max retries reached');
        }

        // Wait for a specified delay before the next retry
        await new Promise(resolve => setTimeout(resolve, delay));
    }
  }
  
  setIsLoading(false); // Reset loading state to false when API call is done
};




  return (
    <div className="user-story-container">
      <h1 className="main-heading">Initiative Generator</h1>
      <h3 className="sub-heading">Describe your feature in detail to generate better results.</h3>
      
      <div classname="labeldiv">
        <label htmlFor="featuresDescription" className="label">Initiative description:</label>
      </div>
      
      <textarea
        className="user-story-textarea"
        rows="5"
        onChange={(e) => setDescription(e.target.value)}
      />
      {!isLoading ? <button className="user-story-button" onClick={handleSubmit}>Generate Initiative Details</button> : null}
     
      <div className="user-story-output" id="output-section">
       
        {isLoading ? (
            <div className="loader">{count}s {loaderText}</div>
        ) : (
            <div className="user-story-output output-container">
                <strong className="output-title">Output:</strong>
                {/*formatOutput(output)*/}
                
                {output && typeof output === 'object' ? 
                            renderJSON(output) : // Render JSON using your utility function
                            error
                        }
                <i className="fas fa-copy copy-icon" onClick={() => copyToClipboard('output-section')}></i>
            </div>
        )}
      </div>
    </div>
  );
}

export default InitiativeComponent;
