import React, { useState, useEffect } from 'react';
// import axios from 'axios';
import './prioritizeIdeas.css';  
import '../../common/css/loader.css';
import { ideasPrioritizationGPT4, createChatCompletionGPT4 } from '../../common/utils/utilityFunctions';
// import { exit } from 'process';

const PrioritizeIdeas = () => {
  const [textDescription, setTextDescription] = useState('');
  const [apiOutput, setApiOutput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [count, setCount] = useState(0);
  const [loaderText, setLoaderText] = useState('');
  const [ideasList, setIdeasList] = useState([]);

  useEffect(() => {
    
    let timer;
    if (isLoading) {
      timer = setInterval(() => {
        setCount(prevCount => prevCount + 1);
      }, 1000);
    } else {
      setCount(0);  // Reset the counter when loading is complete
    }
    return () => clearInterval(timer);  // Cleanup: stop the timer
  }, [isLoading]);

  function chunkArray(array, chunkSize) {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  }

  const handleTextAreaChange = (e) => {
    setTextDescription(e.target.value);
    const text = e.target.value;
    const ideasArray = text.split('\n').filter(line => line.trim() !== '');
    
    console.log("Before Storing in State variable:\n",ideasArray);
    setIdeasList(ideasArray);
    console.log("ideasList: \n",ideasList);
  };

  const generatePrompt = (description) => {
    const formattedIdeas = description.join('\n');

    return `Prioritize the ideas give using RICE and KANO models. The ideas give are:\n ${formattedIdeas}.\n For RICE model use a scale of 1 to 10. \n For Kano model, use the classifaction as basic, Performance, Delightful, Neutral, Reverse. Provide reasoning for each scale rating given. \n I want the output in JSON format. \n It should be in the format as Idea, R , Explanation on why R was rated X,  I and Explanation on why I was rated X, C and Explanation on why C was rated X, E and Explanation on why E was rated X, RICE Score, Kano Model, explanation why it was rated that particular classofication of the Kano model as per the given JSON format.`;
  };

  //Help Icon
  const [showHelpModal, setShowHelpModal] = useState(false); // State to manage help modal visibility
  const toggleHelpModal = () => { // Function to toggle help modal
    setShowHelpModal(!showHelpModal);
  };

  

  // const generateIdeas = async () => {
  //   setIsLoading(true);
  //   setLoaderText('Prioritizing Ideas...');
  //   const originalText = `You are a product manager assistant.\n${textDescription}`;

  //   // Split the array into chunks of 5
  //   const chunkedIdeas = chunkArray(ideasList, 5);

  //   // Process each chunk
  //   chunkedIdeas.forEach(ideasChunk => {
  //     let prompt = generatePrompt(ideasChunk);
  //     // Do something with the prompt here
    

  //   // let prompt = generatePrompt(textDescription);

    

  //   try {
  //     // const response = await axios.post('http://localhost:5000/call_OpenAI', {
  //     //   prompt: prompt,
  //     // });

  //     const response1 = await ideasPrioritizationGPT4(prompt);
  //     console.log("Response from GPT4:", response1);
      
  //     // setApiOutput(response.data.message);
  //     if (typeof response1 === 'string') {
  //       console.log("String response:", response1);
  //       setApiOutput(response1);
  //     } else {
  //       try{
  //         console.log("Stringified response:");
  //         const jsonString = JSON.stringify(response1);
  //         setApiOutput(jsonString);
  //       }
  //       catch(err){
  //         console.log(err);
  //         setApiOutput("Error in parsing AI output. Please try again.");
  //       }
  //     }
      
      

  //           setLoaderText('Done!');

  //     // console.log("\First List: " + response1);
  //     // console.log("\Second List: " + response2);


  //     // setApiOutput(ideasList);
  //     // setApiOutput(ideasList);

  //   } catch (error) {
  //     console.error("An error occurred while fetching data:", error);
  //     console.error("Server response:", error.response);
  //     setApiOutput("An error occurred while generating ideas.\nPlease try again.\nError is: " + error.response);
  //   }
  // });

  //   setIsLoading(false);
  // };

  const generateIdeas = async () => {
    setIsLoading(true);
    setLoaderText('Prioritizing Ideas...');
  
    // const originalText = `You are a product manager assistant.\n${textDescription}`;
    const chunkedIdeas = chunkArray(ideasList, 5);
    let allResponses = [];  // Array to store responses from each chunk
  
    let i=0;
    try {
      for (const ideasChunk of chunkedIdeas) {
        let prompt = generatePrompt(ideasChunk);
        i=i+1;
        const response = await ideasPrioritizationGPT4(prompt);
        console.log("Response from GPT4 for a chunk " + i+":", response);
  
        // Process the response as needed and add it to the allResponses array
        allResponses.push(response); 
      }
  
      // Combine the responses from all chunks
      const combinedResponse = allResponses.join("\n"); // or any other logic to combine
      setApiOutput(combinedResponse);
      setLoaderText('Done!');
  
    } catch (error) {
      console.error("An error occurred while fetching data:", error);
      console.error("Server response:", error.response);
      setApiOutput("An error occurred while generating ideas.\nPlease try again.\nError is: " + error.response);
    } finally {
      setIsLoading(false);
    }
  };
  

  return (
    <div className="container">
      <div className="header">
        <div className="header-text">
          <h1>Prioritize Ideas</h1>          
        </div>
        <button className="info-button" onClick={toggleHelpModal}>i</button>
      </div>

      <label htmlFor="textDescription" className="label">Enter the ideas you'd like to Prioritize:</label>
      <div className="example-text"> For a detailed and meaningful analysis, it's often more practical to focus on a smaller set of ideas, typically around 5 to 10 at a time. This allows for a more thorough examination of each idea, considering various factors such as feasibility, potential impact, and innovation. </div>

      {/* Help Modal */}
      {showHelpModal && (
        <div className="help-modal">
          <button className="close-button" onClick={toggleHelpModal}>×</button>
          <div className="help-content">
            <h4>RICE Model Scoring and Kano Model Classification</h4>
            <p> Understanding these models is helpful for effective feature prioritization in product management:</p>
            <hr></hr>
            <h4><strong>RICE Model Scoring:</strong> A method to evaluate and prioritize product features or projects.</h4>
            <ul style={{ marginRight: `${2}em`, textAlign: 'left' }}>
              <li><strong>Score:</strong> Calculated by multiplying Reach, Impact, and Confidence, and dividing by Effort.</li>
              <li><strong>Reach (1-10):</strong> Estimates the number of users impacted by a feature or project.</li>
              <li><strong>Impact (1-10):</strong> Assesses the degree of positive change a feature or project will bring.</li>
              <li><strong>Confidence (1-10):</strong> Reflects how certain you are about your estimates of Reach and Impact.</li>
              <li><strong>Effort (1-10):</strong> Represents the resources (time, cost, labor) needed for a feature or project. A higher score means more effort.</li>
            </ul>
            <hr></hr>

            <h4><strong>Kano Model Classification:</strong> A framework for categorizing product features based on customer preferences.</h4>
            <ul style={{ marginRight: `${2}em`, textAlign: 'left' }}>
              <li>Prioritize features based on the degree to which they are likely to satisfy customers. Product teams can weigh a high-satisfaction feature against its costs to implement to determine whether or not adding it to the roadmap is a strategically sound decision.</li>
              <li><strong>Basic:</strong> Essential features that are expected by users. Absence of these can lead to dissatisfaction.</li>
              <li><strong>Performance:</strong> Features that enhance the product's effectiveness and efficiency. These are often directly correlated to user satisfaction.</li>
              <li><strong>Delightful:</strong> Features that significantly increase user satisfaction, often unexpectedly.</li>
              <li><strong>Neutral:</strong> Features with little to no effect on user perception or satisfaction.</li>
              <li><strong>Reverse:</strong> Features that could negatively impact the user experience if included.</li>
            </ul>
            <p>Applying these models helps in making informed decisions about product development and prioritization, leading to better user satisfaction and product success.</p>
          </div>
        
        </div>
        
      )}

      <textarea
        id="textDescription"
        rows="10"
        className="text-area"
        value={textDescription}
        onChange={handleTextAreaChange}
      ></textarea>
      {!isLoading ?
        <button onClick={generateIdeas} className="optimize-button">Prioritize Ideas</button>
      : null}
      {isLoading ? (
        <div className="loader">{count}s {loaderText}</div>
      ) : (
        <div className="optimized-text">
          <h3>Generated Ideas:</h3>
          <h4 style={{ fontWeight: 'bold'}}>Notice: All AI-generated content must undergo human review prior to publication.</h4>
          {apiOutput ?
              (typeof apiOutput === 'string' ?
                  <div style={{ height: '400px', overflowY: 'scroll' }} dangerouslySetInnerHTML={{ __html: apiOutput }} /> :
                  <pre>Error in receiving AI response. Please try again.</pre>
              ) :
              (error ? <div>Error: {error}</div> : null)
          }

        </div>
      )}
    </div>
  );
};

export default PrioritizeIdeas;
