import React, { useState, useEffect } from 'react';
import CryptoJS from 'crypto-js';
import './common/css/App.css'; // Make sure you have this file to include styles
// import UserStoryComponent from ''; // Import the User Story component
import UserStoryComponent from './components/userStoryComponent';
import TestCasesComponent from './components/testCasesComponent';
import TestUtilityFunctions from './components/testUtilityComponent';
import ReleaseNoteGenerator from './components/releaseNoteGeneratorComponent';
import TextOptimizer from './components/testOptimizerComponent';
import IdeaSorter from './components/sortIdeasComponent';
import PlantUMLGenerator from './components/plantUMLComponent/plantUML';
import PressReleaseGenerator from './components/pressReleaseComponent';
import IdeasGenerator from './components/prioritizeIdeasComponent';
import { MsalProvider, AuthenticatedTemplate, useMsal, UnauthenticatedTemplate } from '@azure/msal-react';
import { Container } from 'react-bootstrap';
import { PageLayout } from './components/PageLayout';
import { IdTokenData } from './components/DataDisplay';
import { loginRequest } from './authConfig';
import OpenAI from 'openai';
//import TestingComponent from './components/TestingComponent';
import InitiativeComponent from './components/initiativeComponent';
import VisionUserStoryComponent from './components/visionUserStoryComponent';
import PrioritizeIdeas from './components/prioritizeIdeasComponent';

// import './App.css';

//const { OpenAIAPI } = require("openai");
//const OpenAIAPI = require('openai');

const HeaderContent = () => {
  return (
    <header className="App-header">
      <i className="fa fa-rocket App-logo" aria-hidden="true"></i>
      <h2>Welcome to PRODigy AI</h2>
      <h3>
        From Idea to Launch: PRODigy AI Helps You Every Step of the Way!
      </h3>
    </header>
  );
}

const AuthorizedContent = () => {
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [key, setKey] = useState('');
  const [isKeySaved, setIsKeySaved] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [apiError, setApiError] = useState('');

  useEffect(() => {
    const decryptedKey = readAndDecryptKey();
    if (decryptedKey) {
      setKey(decryptedKey);
      setIsKeySaved(true); // Hide input and button
    }
  }, []);

  // Function to handle key input change
  const handleInputChange = (e) => {
    setKey(e.target.value);
  };

  // // Function to show the popup
  // const handleShowPopup = () => {
  //   setShowPopup(true);
  // };

  // Function to validate the key
  const validateApiKey = async (apiKey) => {
     
    const OPENAI_API_KEY = apiKey   
    const openai =new OpenAI({ apiKey: OPENAI_API_KEY,dangerouslyAllowBrowser: true });
     
    try {
        const chatCompletion = await openai.chat.completions.create({
        model: "gpt-3.5-turbo-16k",      
        messages: [
          { role: "system", content: "Translator AI assistant"},
          { role: "user", content: "Translate the following English text to French: Hello" }       
        ],
      });
       
      return true;
    } catch (error) {
      setApiError(`\Message: ${error}`);
      console.error(`Invalid API Key: ${error}`);
      return false;
    }
  };

  // Function to encrypt and save the key to local storage
  const handleSaveKey = async () => {
    try {
       
      const isValid = await validateApiKey(key);
      if (!isValid) {     
        let errorMessage =  "validateApiKey: API could not be validated. You can find your API key at https://platform.openai.com/account/api-keys.\n" + apiError;  
        alert(errorMessage);
        return;
      }
      
  
      // Encrypt the key using AES encryption
      const encryptedKey = CryptoJS.AES.encrypt(key, 'cc2df04b-8761-4dfb-9fac-d8c1dd6c7caa').toString();
  
      // Save the encrypted key to local storage
      localStorage.setItem('encryptedKey', encryptedKey);
  
      // Close the popup
      setShowPopup(false);
  
       
      setIsKeySaved(true); // Hide input and button
    } catch (error) {
      setShowPopup(true);
      alert("Error! API key is invalid or something went wrong.");
      console.error(error);
    }
  };
  

  const readAndDecryptKey = () => {
    const encryptedKey = localStorage.getItem('encryptedKey');
    if (!encryptedKey) {
      console.log('No key found in local storage.');
      setIsKeySaved(false);
       
      return null;
    } else {
      setIsKeySaved(true);
    }
  };

  const featureList = [
    { name: 'User Story', icon: 'person' },
    { name: 'User Story From Image', icon: 'collections' },
    { name: 'Create Product Initiative', icon: 'dashboard' },
    { name: 'Test Cases', icon: 'check_circle' },
    { name: 'Diagram Generator', icon: 'bubble_chart' },
    { name: 'Release Notes Generator', icon: 'notes' },
    { name: 'Text Optimizer', icon: 'text_fields' },
    { name: 'Idea Generator', icon: 'lightbulb' },
    { name: 'Sort Ideas into Categories', icon: 'sort' },
    { name: 'Prioritize Ideas', icon: 'sort' },
    { name: 'Generate a Press Release', icon: 'newspaper' },    
    { name: 'Semantic Analysis', icon: 'insights' }    
    // { name: 'Test Backend', icon: 'build' }

  ];

  const renderRightPanel = () => {
    switch (selectedFeature) {
      case 'User Story':
        return <UserStoryComponent />;
      case 'User Story From Image':
        return <VisionUserStoryComponent />;
      case 'Test Cases':
        return <TestCasesComponent />;
      case 'Diagram Generator':
        return <PlantUMLGenerator />;
      case 'Release Notes Generator':
        return <ReleaseNoteGenerator />;
      case 'Text Optimizer':
        return <TextOptimizer />;
      case 'Sort Ideas into Categories':
        return <IdeaSorter />;
      case 'Generate a Press Release':
        return <PressReleaseGenerator />;
      case 'Idea Generator':
        return <IdeasGenerator />;
      case 'Create Product Initiative':
          return <InitiativeComponent />;

      case 'Prioritize Ideas':
        return <PrioritizeIdeas />;
      // case 'Test Backend':
      //   return <TestingComponent />;
      default:
        return <p>Select a feature to display its fields.</p>;
    }
  };

  return (
    <div className="App">

      {!isKeySaved && (
        <div className="popup">
          <input
            type="password"
            value={key}
            onChange={handleInputChange}
            placeholder="Enter valid Open AI API key"
          />
          <button 
            className="btn btn-primary popupButton" 
            onClick={handleSaveKey}
            disabled={!key.trim() || !/^sk-[a-zA-Z0-9]+$|^pk-[a-zA-Z0-9]+$/.test(key)}
          >
            Save API Key
          </button>
          <div className='helpIcon'> 
            
            <a href="OpenAI_API_Help.html" target="_blank" rel="noopener noreferrer" className="info-icon-link helpIcon">Require API Key? Click here for detailed steps.
            <i className="material-icons">info</i>
            </a>
          </div>
        </div>
      )}

      <div className="App-body">
        <div className="left-panel">
          <h3>Features:</h3>
          <ul>
            {featureList.map((featureObj) => (
              <li
                key={featureObj.name}
                onClick={() => {
                  
                  if (isKeySaved) {
                    setSelectedFeature(featureObj.name);
                  } else if (!isKeySaved || ['Semantic Analysis'].includes(featureObj.name)) {
                    alert("API Key not saved. Please enter and save your Open AI API key first.");
                  }
                }}
                className={!isKeySaved || ['Semantic Analysis'].includes(featureObj.name) ? 'greyed-out' : ''}
              >           
              
              
                <i className="material-icons">{featureObj.icon}</i>
                {featureObj.name}
              </li>
            ))}
          </ul>
        </div>
        <div className="right-panel">

          {renderRightPanel()}
          {/* <lable style={{ textAlign: 'right' }}>Feedback Slack Channel: #prodigy-ai-discussion</lable> */}
        </div>
      </div>

      <div className='footer'>
        <br/>
        <lable>Feedback Slack Channel: #prodigy-ai-discussion</lable>
      </div>

    </div>
  );
};

const MainContent = () => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const handleLoginPopup = () => {

    instance
      .loginPopup({
        ...loginRequest,
        redirectUri: '/redirect',
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="App">
      <AuthenticatedTemplate>
        <HeaderContent />
        {activeAccount ? (
          <AuthorizedContent />
        ) : null}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <HeaderContent />
        <button className="genericButton" as="button" onClick={handleLoginPopup}>Sign-In with Microsoft</button>
      </UnauthenticatedTemplate>
    </div>
  );
};

const App = ({ instance }) => {
  return (
    <Container>
      <MsalProvider instance={instance}>
        <MainContent />
      </MsalProvider>
    </Container>

  );
}

export default App;
